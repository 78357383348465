<template>
  <div v-if="show" class="dialog">
    <div class="rounded_card" style="width:80%;position:relative;max-width:700px">
      <template v-if="type=='notice'">
        <div style="display: flex;flex-direction: column;padding: 20px;">
          <div :style="'padding:0px;font-size:1.3em;font-weight:bold;margin-bottom:15px'">
            {{ preview_title }}
          </div>

          <div style="width: 100%;overflow-y: auto;max-height: 60vh;">
            <div
                :class="'fr-element fr-view'" style="width:100%;margin:auto;">
              <div v-html="preview_contents">
              </div>
            </div>
          </div>

          <div class="add_items"
               :style="';margin-top:15px;display: flex;width: 100%;padding:0;font-size:'+(default_font_size*1.1)+'em;font-weight:bold;'">
            <div style="flex: 1;padding:0">
              <div class="rounded_card w100_h100 cancel"
                   style="padding: 15px;margin: 10px;cursor: pointer;color: #666;border-color: #666;"
                   @click.stop="close(1)"
              >
                {{ $t('confirm') }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="type=='qr'">
        <div :style="'padding: 20px 15px 15px 20px;font-size:1.3em;font-weight:bold;'">
          {{ $t('smartphone_qr_order') }}
        </div>
        <div
            class="detailInfo"
            :style="'padding: 1em 1em;font-size:1.6em;white-space:pre-line;'"
        >
          {{ $t('qr_code_instructions') }}
        </div>

        <div style="display: flex;width: 100%;justify-content: center">
          <qr-code :size="170" :text="getSmartorderUrl()" style="justify-content: center;margin-top: 15px"/>
        </div>

        <div class="add_items"
             :style="'margin-top:15px;display: flex;width: 100%;padding: 10px 0;font-size:'+(default_font_size*1.1)+'em;font-weight:bold;'">
          <div style="flex: 1;padding: 20px 20px 0 20px">
            <div class="rounded_card w100_h100 cancel"
                 style="padding: 15px;margin: 10px;cursor: pointer;color: #666;border-color: #666;"
                 @click.stop="close(1)"
            >
              {{ $t('confirm') }}
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="type=='review_guide'">

        <div style="display: flex;flex-direction: column;max-height: 90vh">
          <div style="flex-grow: 1;overflow-y:auto">
            <review-event />

          </div>
          <div class="add_items"
               :style="'margin-top:0px;display: flex;width: 100%;padding: 10px 0;font-size:'+(default_font_size*1.1)+'em;font-weight:bold;'">
            <div style="flex: 1;padding: 20px 20px 0 20px">
              <div class="rounded_card w100_h100 cancel"
                   style="padding: 15px;margin: 10px;cursor: pointer;color: #666;border-color: #666;"
                   @click.stop="close(1)"
              >
                {{ $t('confirm') }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="type=='apply_sale'">
        <div style="padding: 20px;font-size: 1.2em">

          <div style="padding: 20px">
            <my-input-text
                v-model="sale_code"
                mtype="text"
                ref="addr_input"
                @enter="apply_code"
                autofocus
                :label="$t('enter_discount_code')"/>
          </div>


          <div style="flex: 1;padding: 5px 20px 0 20px;display: flex">

            <div class="rounded_card w100_h100 cancel"
                 style="padding: 15px;margin: 10px;cursor: pointer;color: #666;border-color: #666;"
                 @click.stop="close()"
            >
              {{ $t('cancel') }}
            </div>


            <div class="rounded_card w100_h100 btn_next"
                 style="flex:2;padding: 15px;margin: 10px;cursor: pointer;color: #fff;border-color: #666;"
                 @click.stop="apply_code()"
            >
              {{ $t('apply_code') }}
            </div>

          </div>
        </div>
      </template>

      <template v-else-if="type=='qr_guide'">

        <div style="display: flex;flex-direction: column;max-height: 90vh">

          <div :style="'padding: 20px 15px 15px 20px;font-size:1.3em;font-weight:bold;'">
            {{ $t('point_usage') }}
          </div>

          <div style="flex-grow: 1;overflow-y: auto">
            <point-event/>
          </div>

          <div class="add_items"
               :style="'margin-top:0px;display: flex;width: 100%;padding: 10px 0;font-size:'+(default_font_size*1.1)+'em;font-weight:bold;'">
            <div style="flex: 1;padding: 5px 20px 0 20px;display: flex">

              <div class="rounded_card paybt"
                   style="padding: 15px;margin: 10px;cursor: pointer;"
                   @click.stop="go_apps()"
              >
                {{ $t('install_app') }}
              </div>


              <div class="rounded_card w100_h100 cancel"
                   style="padding: 15px;margin: 10px;cursor: pointer;color: #666;border-color: #666;"
                   @click.stop="close()"
              >
                {{ $t('confirm') }}
              </div>

            </div>
          </div>
        </div>

      </template>

      <template v-else-if="type=='download_app'">
        <div :style="'padding: 20px 15px 15px 20px;font-size:1.3em;font-weight:bold;'">
          {{ $t('download_checkout_app') }}
        </div>

        <div style="text-align: center;padding: 15px;">
          <img src="/images/app_download.png" style="width: 90%">
        </div>

        <div class="add_items"
             :style="'margin-top:0px;display: flex;width: 100%;padding: 10px 0;font-size:'+(default_font_size*1.1)+'em;font-weight:bold;'">
          <div style="flex: 1;padding: 5px 20px 0 20px;display: flex">

            <div class="rounded_card w100_h100 cancel"
                 style="padding: 15px;margin: 10px;cursor: pointer;color: #666;border-color: #666;"
                 @click.stop="go_guide()"
            >
              {{ $t('confirm') }}
            </div>

          </div>
        </div>
      </template>
      <template
          v-else
      >
        <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 22px;">
          <div :style="'padding: 20px 15px 15px 20px;font-size:1.3em;font-weight:bold;'">
            {{ title }}
          </div>
          <div style="width: 80%;margin-top: 10px" class="msform" v-if="input">
            <input type="text" v-model="input_txt">
          </div>
          <div class="add_items"
               :style="'margin-top:15px;display: flex;width: 100%;padding: 10px 0 0 10px;font-size:'+(default_font_size*1.1)+'em;font-weight:bold;'">
            <div style="flex: 1">
              <div class="rounded_card w100_h100 cancel"
                   style="padding: 15px;margin: 10px;cursor: pointer"
                   @click.stop="close(1)"
              >
                {{ $t('cancel') }}
              </div>
            </div>
            <div style="flex: 1">
              <div class="rounded_card w100_h100 btn_next"
                   @click.stop="ok1(1)"
                   style="padding: 15px;margin: 10px;cursor: pointer"
              >
                {{ $t('confirm') }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import VueQRCodeComponent from 'vue-qrcode-component';
import store from "@/store";
import MyInputText from "@/components/common/MyInputText.vue";
import ReviewEvent from "@/components/page/ReviewEvent.vue";
import PointEvent from "@/components/page/PointEvent.vue";

export default {
  components: {
    PointEvent,
    MyInputText,
    'qr-code': VueQRCodeComponent,
    ReviewEvent
  },
  name: "confirm_popup",
  computed: mapGetters({
    tablet_auth:'tablet_auth',
    offline_info: 'offline_info',
    shop_info: 'shop_info',
    torder_no: 'torder_no',
    shop_uid: 'shop_uid',
    default_font_size: 'default_font_size',
  }),
  mounted() {
    this.emitter.on('confirm_popup', this.open);
    this.emitter.on('confirm_popup_close', this.close);
  },
  data: () => ({
    sale_code: '',
    preview_title: '',
    preview_contents: '',
    preview_ver: 1,
    type: '',
    title: '',
    show: false,
    input: false,
    input_txt: '',
    callback: null,
  }),
  methods: {
    submit_review() {

    },
    getSmartorderUrl() {
      let SMARTORDER_URL = store.state.my_env('smartorder');
      var url = "https://gw." + SMARTORDER_URL + "/api/main/start_order?shopid=" + this.shop_uid + "&tid=" + this.torder_no;
      return url;
    },
    open(o) {
      this.show = true;

      this.callback = o.callback;
      this.input = !!o.input;
      this.title = o.title;

      if (o.qr) {
        this.type = "qr";
      } else if (o.qr_guide) {
        this.type = "qr_guide";
      } else if (o.review_guide) {
        this.type = "review_guide";
      } else if (o.apply_sale) {
        this.type = "apply_sale";
      } else if (o.download_app) {
        this.type = "download_app";
      } else if (o.notice) {
        this.type = "notice";

        // load contents
        this.load_contents(o.value);
      } else {
        this.type = "";
      }
    },
    getNoticeDetail(id, callback) {
      var url = "/api/myshp/get/notice/detail?shopid=" + this.shop_info.shopid + "&id=" + id;
      this.$http.get(url)
          .then(data => {
            var v = data.data;
            callback(v);
          });
    },
    load_contents(value) {
      var v = this;
      this.getNoticeDetail(value, function (data) {
        v.preview_title = data.title;
        v.preview_contents = data.contents;
        v.preview_ver = data.ver;
      });
    },
    go_guide() {
      this.emitter.emit("confirm_popup", {
        qr_guide: true,
      });
    },
    apply_code() {
      // 코드 적용
      //this.sale_code

      var data = JSON.parse(this.offline_info.sale_ref)
      data.code = this.sale_code
      data.uid = this.shop_uid
      var url = "/core-api/main/check/offline/sale_code/v2";

      this.$http.post(
          url, data
      ).then(res => {

        let cached_code = res.data
        this.$store.commit('sale_code', { value: cached_code });

        // 카트를 비운다
        try {
          localStorage.removeItem('cart_v2')
          localStorage.removeItem('cart_v2_hash')
          localStorage.removeItem('cart_v2_exp')

          this.$store.commit('cart_items', {value: []})

        } catch (e) {
        }

        this.$toast.info(this.$t('applied_successfully'), {
          position: "top",
        });

        this.apply_sale_order();
      }).catch(err => {
        this.$toast.error(this.$t('invalid_code'), {
          position: "top",
        });
      });

      this.show = false;
    },
    apply_sale_order() {
      //APPLY_SALE
      var data = this.offline_info.apply_sale;
      //console.log(data);
      this.$store.commit('member_sale', { value: data });
    },
    go_apps() {
      // Check if the userAgent string contains 'Android'
      const isAndroid = /Android/.test(navigator.userAgent);

      // Check if the userAgent string contains 'iPhone'
      const isiPhone = /iPhone/.test(navigator.userAgent);


      if(this.tablet_auth) {
        // open
        this.emitter.emit("confirm_popup", {
          download_app: true,
        });
        return
      }

      // Example usage
      if (isAndroid) {
        window.open('https://play.google.com/store/apps/details?id=com.mocoplex.myshop.checkout&hl=en_US&pli=1');
      } else if (isiPhone) {
        window.open('https://apps.apple.com/kr/app/%EB%A7%88%EC%9D%B4%EC%83%B5-%EC%B2%B4%ED%81%AC%EC%95%84%EC%9B%83/id1590822341');
      } else {
        // open
        this.emitter.emit("confirm_popup", {
          download_app: true,
        });
        //download_app
      }
    },
    close() {
      this.show = false;
    },
    ok1() {
      try {
        //console.log(this.callback)
        this.callback(this.input_txt);
      } catch (e) {
        console.log(e);
      }
      this.show = false;
    },
  },
};
</script>
<style scoped>
.clk_event:active {
  background-color: var(--qro-primary-color);
  color: white;
}


/*inputs*/
.msform input, .msform textarea {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2C3E50;
  font-size: 13px;
}

.msform input:focus, .msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #ee0979;
  outline-width: 0;
  transition: All 0.5s ease-in;
  -webkit-transition: All 0.5s ease-in;
  -moz-transition: All 0.5s ease-in;
  -o-transition: All 0.5s ease-in;
}
</style>