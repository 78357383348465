<template>
  <VueSidePanel
      v-model="show"
      rerender
      hide-close-btn
      side="bottom"
      :z-index="2"
      :panel-duration="200"
      :overlay-duration="0"
  >
    <template #default>

      <div style="height: 100%; display: flex; flex-direction: column; overflow-y: auto">

        <div style="font-weight: bold; font-size: 1.3em; padding: 25px">
          {{ $t('select_call_type') }}
        </div>

        <div style="padding: 5px 20px; overflow-y: auto">
          <div class="paybt"
               @click="noti('bell')"
               :style="'font-size:'+(default_font_size*1.1)+'em; margin:15px 0'"
          >
            <div class="pay">
              {{ $t('general_call') }}
            </div>
          </div>
          <div class="paybt"
               v-for="item in items"
               :key="item.request_text"
               @click="noti(item.request_text)"
               :style="'font-size:'+(default_font_size*1.1)+'em; margin:15px 0'"
          >
            <div class="pay">
              {{ item.alt_name?item.alt_name:item.request_text }}
            </div>
          </div>

          <div class="paybt"
               @click="custom"
               :style="'font-size:'+(default_font_size*1.1)+'em; margin:15px 0'"
          >
            <div class="pay">
              <svg xmlns="http://www.w3.org/2000/svg" fill="white" style="vertical-align: middle; margin-bottom: 3px;" height="16" width="18" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"/></svg>
              {{ $t('direct_input') }}
            </div>
          </div>
        </div>

      </div>

    </template>

    <template #footer>

      <div :style="window_vertical ? 'display:flex; padding: 1em 1em 1em 1em;' : 'display:flex; padding: 1em 1em 1em 1em;'">

        <div class="cancelbt" @click.stop="close()"
             :style="'font-size:'+(default_font_size*1.1)+'em; margin-right: 10px'"
        >
          <div style="align-self: center">
            {{ $t('close') }}
          </div>
        </div>
      </div>

    </template>
  </VueSidePanel>
</template>

<script>
import MyNumber from "@/components/form/MyNumber";
import { directive } from 'v-aspect-ratio'
import myshopDetailView from "@/mixin/DetailViewMixin";
import { mapGetters } from "vuex";
import OptionLayer from "@/components/common/OptionLayer";
import VueQRCodeComponent from 'vue-qrcode-component'

export default {
  name: "AlarmPopup",
  directives: {
    'aspect-ratio': directive,
  },
  created() {
    var w = window.innerWidth
    if (w > 500) {
      this.window_vertical = false
    } else {
      this.window_vertical = true
    }
  },
  computed: mapGetters({
    lang_current_code:'lang_current_code',
    admin_pos: 'admin_pos',
    vertical: 'vertical',
    torder_no: 'torder_no',
    prod_restriction: 'prod_restriction',
    default_font_size: 'default_font_size',
    products: 'products',
    cart_items: 'cart_items',
    selected_product: 'selected_product',
    myshop_host: 'myshop_host',
    shop_uid: 'shop_uid',
    prod_options: 'prod_options',
    option_mappings: 'option_mappings'
  }),
  mixins: [myshopDetailView],
  components: {
    OptionLayer,
    MyNumber
  },
  mounted() {
  },
  watch: {
    selected_product: {
      deep: true,
      handler() {
      }
    },
    show: function () {
      if (!this.show)
        this.close()
    }
  },
  data: () => ({
    items: null,
    window_vertical: false,
    show: false,
    cart_popup_products: [],
    qty: 1
  }),
  methods: {
    noti: function (ty) {

      var o = {
        'table_no': this.torder_no, // 주문 정보
        'request_type_id': null, // 요청 타입 ID, 직접 입력인 경우 NULL
        'request_text': ty,
        //'password': '01088844434',
        'uid': this.shop_uid
      }

      var url = '/torder-api/tableorder/request?'

      this.$http.post(
          url, o
      ).then(res => {
        this.$toast.info(this.$t('complete_call'),
            {
              position: "top"
            });
      }).catch(err => {
      })

      this.show = false
    },
    custom: function () {
      var that = this
      this.emitter.emit("confirm_popup",
          {
            title: this.$t('enter_text'),
            input: true,
            callback: function (txt) {
              if (!txt) {
                this.$toast.error(that.$t('enter_content_first'),
                    {
                      position: "top"
                    });
                return
              }
              that.noti(txt)
              //that.emitter.emit("enter_exist_num_table_order")
            }
          })
    },
    load() {
      //{{real}}/api/tableorder/request-types/customer?tableorder_id=17376_2_20231227084441&uid=17376&password=01025432631
      //var url = '/torder-api/tableorder/request-types?'
      let url = '/core-api/prod/get/request_type/'+this.lang_current_code+'?'
      //let url = '/core-api/tableorder/request-types?'
      url += '&uid=' + this.shop_uid
      //url += '&table_no=' + this.torder_no

      this.$http.get(
          url
      ).then(res => {
        this.items = res.data.request_type_list

        this.open2()
      }).catch(err => {
        this.items = []
        this.open2()
      })

    },
    open() {
      this.load()
    },
    open2() {
      this.show = true
      //console.log(this.default_option_list)

      this.$nextTick(() => {
        setTimeout(function () {

          try {
            var a = document.getElementsByClassName('vsp__body')
            var h = a[0].style.height
            //console.log(h)

            var a = document.getElementsByClassName('detailWrap')
            a[0].style.height = h
            a[0].style.overflowY = 'auto'
          } catch (e) { }
        }, 1000)
      })
    },
    close() {
      this.show = false
    },
  }
}
</script>

<style scoped>
.detailWrap_inner {
  /*
  transform: translate3d(0,0,0);
  -webkit-overflow-scrolling:touch;
   */
  /*height:calc(100% + 1px);*/
}
.detailWrap {
  /*
  overflow:scroll;
  transform: translate3d(0,0,0);
  -webkit-overflow-scrolling:touch;
    height: 100%;
  overflow:auto;
   */
}

/*
.detailWrap {
  transform: translate3d(0,0,0);
  overflow:scroll;
  -webkit-overflow-scrolling:touch;
}
.option_layer {
  height:calc(100% + 1px)
}
*/
.prod_img {
  width: 100%;
  border-radius: 15px;
  background-position: center;
  background-size: cover;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #2f354299;
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  background-color: #e1e1e199;
  border-radius: 5px;
}
.title_pos {
  margin: 0;
}
.xgrid_org_price {
  color: #ddd;
  text-align: right;
  justify-content: right;
}
.xgrid_org_price2 {
  color: var(--qro-primary-color);
  font-weight: bold;
  text-align: right;
  justify-content: right;
}
.xgrid_org_price3 {
  font-weight: bold;
  text-align: right;
  justify-content: right;
}
.badge2 {
  background-color: red;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 5px;
  height: 20px;
  font-size: 16px;
}
</style>
<style>
.vsp__body {
  overflow: hidden !important;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;;
  /*
   */
}
.vsp-wrapper .vsp {
}
.vsp {
  overflow: hidden !important;
  border-top-left-radius: 20px !important;; border-top-right-radius: 20px !important;;
}

</style>
