<template>
  <div class="mcheck" @click="clk">
    <div
    :class="my_value?'mcheck2 on':'mcheck2'"
    >
    <div style="align-self: center;font-family:'Pacifico' !important;">
      V
    </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    modelValue:Boolean
  },
  components: {},
  mounted() {
    this.my_value = this.modelValue
  },
  watch:{
    modelValue:function() {
      this.my_value = this.modelValue
    },
    my_value:function () {
      this.$emit('update:modelValue', this.my_value)
      this.$emit('change', this.my_value)
    },
  },
  computed:{
  },
  data: () => ({
    my_value:false
  }),
  methods:{
    clk:function() {
      this.my_value = !this.my_value
    },
    isValid:function () {
   }
  }
}
</script>
<style scoped>
.mcheck {
  height: 23px;
  width: 23px;
  color: #fff;
}
.mcheck2 {
  border: 1px solid var(--qro-secondary-color);
  height: 100%;
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  color: #fff;
  display: flex;
}
.mcheck2.on {
  border: 1px solid #fff;
  background-color: #3ca9f5;
  border:0;
}
</style>
