<template>
  <VueSidePanel
      v-model="show"
      rerender
      hide-close-btn
      side="bottom"
      :z-index="10"
      :panel-duration="200"
      :overlay-duration="0"
      style="background-color: #ededed"
  >
    <template #default>
      <div style="height: 100%;display: flex;flex-direction: column;overflow-y: auto">
        <div style="font-weight: bold;font-size: 1.3em;padding: 25px">
          {{ $t('dialog.current_table_order') }}
        </div>

        <ad-layer ref="ad" id="status"/>

        <div style="padding: 30px;overflow-y: auto">
          <div style="display: flex;flex-direction: column;justify-content: center">
            <div class="receipt" style="margin-bottom: 40px;width: 90%;align-self: center" v-if="no_order">
              <header class="receipt__header">
                <p class="receipt__title" style="padding: 50px 0">
                  {{ $t('dialog.no_order') }}
                </p>
              </header>
            </div>
            <template v-else-if="summary && details && details.table_info">
              <div class="receipt" style="margin-bottom: 40px;width: 90%;align-self: center">
                <header class="receipt__header">
                  <p class="receipt__title">
                    <svg fill="#FF619B" xmlns="http://www.w3.org/2000/svg" height="16" width="15.5" viewBox="0 0 496 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M248 8C111.1 8 0 119.1 0 256s111.1 248 248 248 248-111.1 248-248S384.9 8 248 8zm114.6 226.4l-113 152.7-112.7-152.7c-8.7-11.9-19.1-50.4 13.6-72 28.1-18.1 54.6-4.2 68.5 11.9 15.9 17.9 46.6 16.9 61.7 0 13.9-16.1 40.4-30 68.1-11.9 32.9 21.6 22.6 60 13.8 72z"/></svg>
                    {{ $t('dialog.thank_you') }}
                    <svg fill="#FF619B" xmlns="http://www.w3.org/2000/svg" height="16" width="15.5" viewBox="0 0 496 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M248 8C111.1 8 0 119.1 0 256s111.1 248 248 248 248-111.1 248-248S384.9 8 248 8zm114.6 226.4l-113 152.7-112.7-152.7c-8.7-11.9-19.1-50.4 13.6-72 28.1-18.1 54.6-4.2 68.5 11.9 15.9 17.9 46.6 16.9 61.7 0 13.9-16.1 40.4-30 68.1-11.9 32.9 21.6 22.6 60 13.8 72z"/></svg>
                  </p>
                  <p class="receipt__date">
                    <svg style="margin-bottom:3px;vertical-align: middle" xmlns="http://www.w3.org/2000/svg" height="13" fill="#666" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"/></svg>
                    {{ $t('dialog.first_order_time') }}
                    {{ times(details.table_info.first_order_date) }}
                  </p>
                </header>
                <dl class="receipt__list">
                  <div class="receipt__list-row" v-for="item in details.product_list">
                    <dt class="receipt__item">{{ item.title }}
                      <span v-if="item.opt_name" style="font-size: 0.8em">* {{item.opt_name}}</span>
                      <span style="font-weight:bold;margin-left:4px;font-size: 1.2em">x{{item.qty}}</span>
                    </dt>
                    <dd class="receipt__cost">₩{{numberWithCommas(item.total_price)}}</dd>
                  </div>
                  <div class="receipt__list-row receipt__list-row--total">
                    <dt class="receipt__item">{{ $t('dialog.total') }}</dt>
                    <dd class="receipt__cost">₩{{numberWithCommas(details.table_info.total_price)}}</dd>
                  </div>
                </dl>
              </div>
            </template>
            <div class="receipt" style="margin-bottom: 40px;width: 90%;align-self: center" v-else-if="!summary && details && details.order_list" v-for="p in details.order_list">
              <header class="receipt__header">
                <p class="receipt__title" v-if="p.order_status == 'ORDERED' || p.order_status == 'READY'">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#FF619B"  height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.--><path d="M416 32a95.2 95.2 0 0 0 -57.7 19.7C334.9 20.5 298 0 256 0s-78.9 20.5-102.3 51.7A95.6 95.6 0 0 0 0 128c0 41.7 64 224 64 224v128a32 32 0 0 0 32 32h320a32 32 0 0 0 32-32V352s64-182.3 64-224a96 96 0 0 0 -96-96zM112 464v-80h288v80zm290.7-128h-29.6L384 201.3a8 8 0 0 0 -7.3-8.6l-16-1.3h-.7a8 8 0 0 0 -8 7.4l-11 137.3h-69.7V200a8 8 0 0 0 -8-8h-16a8 8 0 0 0 -8 8v136h-68.4l-11-137.3a8 8 0 0 0 -8-7.4h-.7l-16 1.3a8 8 0 0 0 -7.3 8.6L138.8 336h-29.6C80 252.8 48.5 149.3 48 128a47.6 47.6 0 0 1 76.7-38l38.5 29.2 28.9-38.7C207.6 59.8 230.9 48 256 48s48.4 11.8 63.8 32.5l28.9 38.7L387.3 90A47.6 47.6 0 0 1 464 127.9c-.5 21.4-32 124.9-61.3 208.1z"/></svg>
                  {{ $t('dialog.preparing') }}
                </p>
                <p class="receipt__title" v-if="p.order_status == 'DONE'">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#FF619B" height="16" width="16" style="vertical-align: middle" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.--><path d="M332.7 178.4c-3.9-3.9-10.1-3.9-14-.1l-87.4 86.7-37.9-38.2c-3.8-3.9-10.1-3.9-14-.1l-23.4 23.2c-3.9 3.9-3.9 10.1-.1 14l68.1 68.6c3.9 3.9 10.1 3.9 14 .1l117.8-116.8c3.9-3.9 3.9-10.1 .1-14l-23.2-23.4zM512 256c0-36.5-18.8-68.4-46.7-86.7 6.8-32.9-2.7-68.7-28.3-94.3-25.6-25.7-61.5-35.1-94.3-28.3C324.2 18.7 292.3 0 256 0s-68.3 18.7-86.7 46.7C136.4 39.9 100.6 49.4 75 75c-25.7 25.6-35.1 61.5-28.3 94.3C18.6 187.8 0 219.8 0 256c0 36.3 18.7 68.3 46.7 86.7-6.8 32.9 2.7 68.7 28.3 94.3 25.6 25.7 61.2 35.1 94.3 28.3 18.5 28.1 50.5 46.7 86.7 46.7 36.4 0 68.3-18.8 86.7-46.7 33.1 6.8 68.7-2.7 94.3-28.3 25.7-25.6 35.1-61.5 28.3-94.3 28.1-18.5 46.7-50.5 46.7-86.7zm-108.3 61.3c9.1 19.9 30.3 54.8-.7 85.8-28 28-55.3 14.5-85.8 .7-7.7 20.6-17.3 60.2-61.2 60.2-45.3 0-54.7-42.8-61.2-60.2-21.1 9.6-54.9 30.2-85.9-.8-32-32-8.4-68.9-.7-85.8C87.6 309.5 48 299.9 48 256c0-45.3 42.8-54.7 60.3-61.3-9.1-19.9-30.3-54.8 .7-85.8 32-32 68.9-8.4 85.8-.7C202.5 87.6 212.1 48 256 48c45.3 0 54.7 42.8 61.2 60.4 19.9-9.1 54.8-30.3 85.8 .7 32 32 8.4 68.9 .7 85.8 20.6 7.7 60.2 17.3 60.2 61.2 0 45.3-42.8 54.7-60.2 61.2z"/></svg>
                  {{ $t('dialog.done') }}
                </p>
                <p class="receipt__title" v-if="p.order_status == 'CANCELED'" style="color: #888">
                  {{ $t('dialog.canceled') }}
                </p>
                <p class="receipt__date">
                  <svg style="margin-bottom:3px;vertical-align: middle" xmlns="http://www.w3.org/2000/svg" height="13" fill="#666" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"/></svg>
                  {{ $t('dialog.order_time') }}
                  {{ times(p.order_date) }}
                </p>
                <p v-if="p.estimated_completion_time" class="receipt__date" style="margin-top: -13px">
                  <svg fill="#666" style="vertical-align:middle;" xmlns="http://www.w3.org/2000/svg" height="13" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M504 255.5c.3 136.6-111.2 248.4-247.8 248.5-64 0-122.4-24.2-166.4-63.9-5.1-4.6-5.3-12.5-.4-17.4l17-17c4.5-4.5 11.8-4.7 16.6-.4C158.2 436.8 204.8 456 256 456c110.5 0 200-89.5 200-200 0-110.5-89.5-200-200-200-55.5 0-105.7 22.6-141.9 59l49.1 48.4c7.6 7.5 2.3 20.5-8.4 20.5H26.4c-6.6 0-12-5.4-12-12V45.4c0-10.7 12.8-16 20.4-8.5l45.1 44.5C124.9 36.1 187.2 8 256 8c136.8 0 247.7 110.8 248 247.5zm-167.1 90.2l14.1-19.4c3.9-5.4 2.7-12.9-2.6-16.8L280 259.8V116c0-6.6-5.4-12-12-12h-24c-6.6 0-12 5.4-12 12v168.2l88.2 64.1c5.4 3.9 12.9 2.7 16.8-2.6z"/></svg>
                  {{ $t('dialog.estimated_completion_time') }}
                  {{ times(p.estimated_completion_time) }}
                </p>
              </header>
              <dl class="receipt__list" :style="p.order_status != 'CANCELED' ? '' : 'text-decoration: line-through'">
                <div class="receipt__list-row" v-for="item in p.product_list">
                  <dt class="receipt__item">{{ item.title }}
                    <span v-if="item.opt_name" style="font-size: 0.8em">* {{item.opt_name}}</span>
                    <span style="margin-left:4px;font-size: 1.1em">x{{item.qty}}</span>
                  </dt>
                  <dd class="receipt__cost">₩{{numberWithCommas(item.total_price)}}</dd>
                </div>
                <div class="receipt__list-row receipt__list-row--total">
                  <dt class="receipt__item">{{ $t('dialog.total') }}</dt>
                  <dd class="receipt__cost">₩{{numberWithCommas(getTotalPrice(p.product_list))}}</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div :style="window_vertical ? 'display:flex;padding: 1em 1em 1em 1em;' : 'display:flex;padding: 1em 1em 1em 1em;'">
        <div class="cancelbt" @click.stop="change_mode()" :style="'font-size:'+(default_font_size*1.1)+'em;margin-right: 10px'">
          <div style="align-self: center">
            <span v-if="summary">
              <svg style="margin-bottom:3px;margin-right:3px;vertical-align: middle" xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M400 64h-48V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H160V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zm-6 400H54a6 6 0 0 1 -6-6V160h352v298a6 6 0 0 1 -6 6zm-52.8-200.7L198.8 404.5c-4.7 4.7-12.3 4.6-17-.1l-75.1-75.7c-4.7-4.7-4.6-12.3 .1-17l22.7-22.5c4.7-4.7 12.3-4.6 17 .1l44.1 44.5 111.1-110.2c4.7-4.7 12.3-4.6 17 .1l22.5 22.7c4.7 4.7 4.6 12.3-.1 17z"/></svg>
              {{ $t('dialog.summary_view') }}
            </span>
            <span v-else>
              <svg xmlns="http://www.w3.org/2000/svg" style="margin-bottom:3px;margin-right:3px;vertical-align: middle" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M80 368H16a16 16 0 0 0 -16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0 -16-16zm0-320H16A16 16 0 0 0 0 64v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16V64a16 16 0 0 0 -16-16zm0 160H16a16 16 0 0 0 -16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0 -16-16zm416 176H176a16 16 0 0 0 -16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0 -16-16zm0-320H176a16 16 0 0 0 -16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V80a16 16 0 0 0 -16-16zm0 160H176a16 16 0 0 0 -16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0 -16-16z"/></svg>
              {{ $t('dialog.detailed_view') }}
            </span>
          </div>
        </div>
        <div class="cancelbt" @click.stop="close()" :style="'font-size:'+(default_font_size*1.1)+'em;margin-right: 10px'">
          <div style="align-self: center">
            {{ $t('dialog.close') }}
          </div>
        </div>
      </div>
    </template>
  </VueSidePanel>
</template>

<script>
import MyNumber from "@/components/form/MyNumber";
import { directive } from 'v-aspect-ratio';
import myshopDetailView from "@/mixin/DetailViewMixin";
import { mapGetters } from "vuex";
import OptionLayer from "@/components/common/OptionLayer";
import moment from 'moment';
import AdLayer from "@/components/common/AdLayer.vue";
import {ref} from "vue";
export default {
  name: "AlarmPopup",
  directives: {
    'aspect-ratio': directive,
  },
  setup() {
    const ad = ref(null)
    return {ad}
  },
  mounted() {
  },
  created() {
    var w = window.innerWidth
    if(w > 500) {
      this.window_vertical = false
    } else {
      this.window_vertical = true
    }
  },
  computed: mapGetters({
    tablet_auth: 'tablet_auth',
    admin_pos: 'admin_pos',
    vertical: 'vertical',
    prod_restriction: 'prod_restriction',
    default_font_size: 'default_font_size',
    products: 'products',
    cart_items: 'cart_items',
    selected_product: 'selected_product',
    myshop_host: 'myshop_host',
    shop_uid: 'shop_uid',
    prod_options: 'prod_options',
    option_mappings: 'option_mappings',
    torder_no: 'torder_no',
    torder_pass: 'torder_pass',
    torder_phone: 'torder_phone',
    torder_type: 'torder_type',
  }),
  mixins: [myshopDetailView],
  components: {
    OptionLayer,
    MyNumber,
    AdLayer
  },
  data: () => ({
    window_vertical: false,
    show: false,
    cart_popup_products: [],
    qty: 1,
    details: null,
    err: '',
    no_order: false,
    summary: true,
    video_loaded: false
  }),
  methods: {
    loadVideoAd:function () {
      this.video_loaded = true
      this.ad.loadVideoAd()
    },
    change_mode() {
      this.summary = !this.summary
    },
    getTotalPrice(plist) {
      var total = 0
      plist.forEach(el => {
        total += el.total_price
      })
      return total
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    times(dt) {
      return moment(dt).format("HH:mm:ss")
    },
    check_my_password(ty, callback, tmp) {
      var url = '/torder-api/tableorder/table/customer?'
      url += '&uid=' + this.shop_uid
      url += '&table_no=' + this.torder_no

      var pass = ''
      if(ty == 'phone') pass = this.torder_phone
      else pass = this.torder_pass

      if(tmp) {
        pass = tmp
      }

      if(!pass) {
        callback(false)
        return
      }

      url += '&password=' + pass
      this.$http.get(url).then(res => {
        this.details = res.data
        this.$store.commit('order_details', { value: this.details })
        callback(true)
      }).catch(err => {
        try {
          if(err.data.errorMessage.indexOf("ERR303") >= 0) {
            this.err = err.data.errorMessage
            callback(false)
            return
          }
          if(err.data.errorMessage.indexOf("ERR302") >= 0) {
            this.err = err.data.errorMessage
            callback(false)
            return
          }
        } catch (e) {}
      })
    },
    get_my_order2(b) {
      this.no_order = false
      if(!b) {
        if(this.err.indexOf("ERR302") >= 0) {
          b = true
          this.no_order = true
        }
      }

      if(b) {
        this.show = true
        this.$nextTick(() => {
          setTimeout(function() {
            try {
              var a = document.getElementsByClassName('vsp__body')
              var h = a[0].style.height
              var a = document.getElementsByClassName('detailWrap')
              a[0].style.height = h
              a[0].style.overflowY = 'auto'
            } catch (e) {}

          }, 1000)

          this.loadVideoAd()
        })
        return
      }

      var that = this
      var callback = function(num) {
        that.check_my_password('pass', function(b) {
          if(b) {
            try {
              that.$store.commit('torder_pass', { value: num })
              if(num.length == 4) {
                that.$cookie.setCookie365('mypass_number', num)
                that.$cookie.setCookie365('mypass_type', 'pass')
                that.$store.commit('torder_type', { value: "pass" })
              } else {
                that.$cookie.setCookie365('myphone_number', num)
                that.$cookie.setCookie365('mypass_type', 'phone')
                that.$store.commit('torder_type', { value: "phone" })
              }
              that.emitter.emit("enter_exist_num_table_order_close")
            } catch(e) {}
            that.get_my_order2(true)
          } else {
            that.$toast.error(`${this.$t('dialog.enter_existing_order_info')}`, { position: "top" })
          }
        }, num)
      }
      that.emitter.emit("enter_exist_num_table_order", callback)
      return
    },
    check_existed_pass(cb) {
      var v = this
      v.check_my_password('phone', function(b) {
        if(!b) {
          v.check_my_password('pass', function(b) {
            if(b) {
              v.$cookie.setCookie365('mypass_type', 'pass')
              v.$store.commit('torder_type', { value: "pass" })
              cb(true)
            } else {
              cb(false)
            }
          })
        } else {
          v.$cookie.setCookie365('mypass_type', 'phone')
          v.$store.commit('torder_type', { value: "phone" })
          cb(true)
        }
      })
    },
    get_my_order_tablet() {
      var url = '/torder-api/tableorder/table/tablet?'
      url += '&table_no=' + this.torder_no
      this.$http.get(url).then(res => {
        this.details = res.data
        this.$store.commit('order_details', { value: this.details })
        this.get_my_order2(true)
      }).catch(err => {
        try {
          if(err.data.errorMessage.indexOf("ERR303") >= 0) {
            this.err = err.data.errorMessage
            this.$toast.error(`${this.$t('dialog.no_order')}`, { position: "top" })
            return
          }
          if(err.data.errorMessage.indexOf("ERR302") >= 0) {
            this.err = err.data.errorMessage
            this.$toast.error(`${this.$t('dialog.no_order')}`, { position: "top" })
            return
          }
        } catch (e) {}
      })
    },
    get_my_order() {
      if(this.tablet_auth) {
        this.get_my_order_tablet()
        return
      }

      var v = this
      var cb = function(b) {
        v.get_my_order2(b)
      }

      if(this.torder_type == 'phone') {
        v.check_my_password('phone', function(b) {
          if(!b) {
            v.check_my_password('pass', function(b) {
              if(b) {
                v.$cookie.setCookie365('mypass_type', 'pass')
                v.$store.commit('torder_type', { value: "pass" })
                cb(true)
              } else {
                cb(false)
              }
            })
          } else {
            v.$cookie.setCookie365('mypass_type', 'phone')
            v.$store.commit('torder_type', { value: "phone" })
            cb(true)
          }
        })
      } else {
        v.check_my_password('pass', function(b) {
          if(!b) {
            v.check_my_password('phone', function(b) {
              if(b) {
                v.$cookie.setCookie365('mypass_type', 'phone')
                v.$store.commit('torder_type', { value: "phone" })
                cb(true)
              } else {
                cb(false)
              }
            })
          } else {
            v.$cookie.setCookie365('mypass_type', 'pass')
            v.$store.commit('torder_type', { value: "pass" })
            cb(true)
          }
        })
      }
    },
    custom() {
      var that = this
      this.emitter.emit("confirm_popup", {
        title: `${this.$t('dialog.enter_existing_order_info')}`,
        input: true,
        callback: function(txt) {
          alert(txt)
        }
      })
    },
    open() {
      this.get_my_order()
    },
    close() {
      this.show = false
    },
  }
}
</script>


<style scoped>
.detailWrap_inner {
  /*
  transform: translate3d(0,0,0);
  -webkit-overflow-scrolling:touch;
   */
  /*height:calc(100% + 1px);*/
}
.detailWrap {
  /*
  overflow:scroll;
  transform: translate3d(0,0,0);
  -webkit-overflow-scrolling:touch;
    height: 100%;
  overflow:auto;
   */
}

/*
.detailWrap {
  transform: translate3d(0,0,0);
  overflow:scroll;
  -webkit-overflow-scrolling:touch;
}
.option_layer {
  height:calc(100% + 1px)
}
*/
.prod_img {
  width: 100%;
  border-radius: 15px;
  background-position: center;
  background-size: cover;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #2f354299;
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  background-color: #e1e1e199;
  border-radius: 5px;
}
.title_pos {
  margin:0;
}
.xgrid_org_price {
  color: #ddd;
  text-align: right;
  justify-content: right;
}
.xgrid_org_price2 {
  color: var(--qro-primary-color);
  font-weight: bold;
  text-align: right;
  justify-content: right;
}
.xgrid_org_price3 {
  font-weight: bold;
  text-align: right;
  justify-content: right;
}
.badge2 {
  background-color: red;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 5px;
  height: 20px;
  font-size: 16px;
}
</style>
<style>
.vsp__body {
  overflow:hidden !important;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;;
  /*
   */
}
.vsp-wrapper .vsp {
}
.vsp {
  overflow:hidden !important;
  border-top-left-radius: 20px  !important;; border-top-right-radius: 20px !important;;
}



/*oo*/

.receipt {
  background-color: #fff;
  width: 22rem;
  position: relative;
  padding: 1rem;
  box-shadow: 0 -0.4rem 1rem -0.4rem rgba(0,0,0,0.2);
}

.receipt:after {
  background-image: linear-gradient(135deg, #fff 0.5rem, transparent 0), linear-gradient(-135deg, #fff 0.5rem, transparent 0);
  background-position: left-bottom;
  background-repeat: repeat-x;
  background-size: 1rem;
  content: '';
  display: block;
  position: absolute;
  bottom: -1rem;
  left: 0;
  width: 100%;
  height: 1rem;
}

.receipt__header {
  text-align: center;
}

.receipt__title {
  color: #FF619B;
  font-size: 1.6rem;
  font-weight: 700;
  margin: 1rem 0 0.5rem;
}

.receipt__date {
  font-size: 0.8rem;
  color: #666;
  margin: 0.5rem 0 1rem;
}

.receipt__list {
  margin: 2rem 0 1rem;
  padding: 0 1rem;
}

.receipt__list-row {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  position: relative;
}

.receipt__list-row:after {
  content: '';
  display: block;
  border-bottom: 1px dotted rgba(0,0,0,0.15);
  width: 100%;
  height: 100%;
  position: absolute;
  top: -0.25rem;
  z-index: 1
}

.receipt__item {
  background-color: #fff;
  z-index: 2;
  padding: 0 0.15rem 0 0;
  color: #1f1f1f;
}

.receipt__cost {
  margin: 0;
  padding: 0 0 0 0.15rem;
  background-color: #fff;
  z-index: 2;
  margin-left: 12px;
  color: #1f1f1f;
}

.receipt__list-row--total {
  border-top: 1px solid #FF619B;
  padding: 1.5rem 0 0;
  margin: 1.5rem 0 0;
  font-weight: 700;
}
</style>
