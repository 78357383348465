<template>
  <div v-if="show" class="dialog">
    <div class="rounded_card" style="width:80%;max-width:600px;max-height:95%;overflow-y:auto">
      <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 20px;">
        <div v-if="tablet_auth"
             class="dialog_content" :style="'font-size:'+(default_font_size*1.5)+'em'">
          {{ $t('dialog.point_save_question') }}
          <br/>
          {{ $t('dialog.enter_phone') }}
        </div>
        <div
            v-else>
          <div
            class="dialog_content" v-html="message" :style="'font-size:'+(default_font_size*1.2)+'em'"/>
          <div v-if="mode=='phone'"
               class="dialog_content"
               :style="'padding-top:0;margin-top:-13px;font-size:'+(default_font_size*1.2)+'em'">
            <my-check v-model="order_noti" :label="$t('make_order_noti')" id="order_noti"/>
          </div>
        </div>



        <div
            :style="'border-bottom: var(--qro-primary-color)2px solid; font-size: '+(default_font_size*1.3)+'em; padding: 0.5em;width: 76%; text-align: center'">
          <span v-if="phone_number" v-html="phone_number"></span>
          <span v-else>&nbsp;</span>
        </div>
        <div style="height: 20px;"></div>
        <div class="dialog_content2" :style="'font-size:'+(default_font_size*1.3)+'em;display:flex;'">
          <div class="btn_number"
               @touchend="add('1',0)"
               @click="add('1',1)"
          >1
          </div>
          <div style="width:2vh"></div>
          <div class="btn_number" @touchend="add('2',0)"
               @click="add('2',1)"
          >2
          </div>
          <div style="width:2vh"></div>
          <div class="btn_number" @touchend="add('3',0)"
               @click="add('3',1)"
          >3
          </div>
        </div>
        <div class="dialog_content2" :style="'font-size:'+(default_font_size*1.3)+'em;display:flex;'">
          <div class="btn_number" @touchend="add('4',0)"
               @click="add('4',1)"
          >4
          </div>
          <div style="width:2vh"></div>
          <div class="btn_number" @touchend="add('5',0)"
               @click="add('5',1)"
          >5
          </div>
          <div style="width:2vh"></div>
          <div class="btn_number" @touchend="add('6',0)"
               @click="add('6',1)"
          >6
          </div>
        </div>
        <div class="dialog_content2" :style="'font-size:'+(default_font_size*1.3)+'em;display:flex;'">
          <div class="btn_number" @touchend="add('7',0)"
               @click="add('7',1)"
          >7
          </div>
          <div style="width:2vh"></div>
          <div class="btn_number" @touchend="add('8',0)"
               @click="add('8',1)"
          >8
          </div>
          <div style="width:2vh"></div>
          <div class="btn_number" @touchend="add('9',0)"
               @click="add('9',1)"
          >9
          </div>
        </div>
        <div class="dialog_content2" :style="'font-size:'+(default_font_size*1.3)+'em;display:flex;'">
          <div class="btn_number" style="border:0"></div>
          <div style="width:2vh"></div>
          <div class="btn_number" @touchend="add('0',0)"
               @click="add('0',1)"
          >0
          </div>
          <div style="width:2vh"></div>
          <div class="btn_number2" @touchend="backspace(0)"
               @click="backspace(1)">
            <img src="../../assets/images/backspace.svg" style="width: 90%;margin-top: 0.7vh;">
          </div>
        </div>
        <template v-if="tablet_auth">
          <div style="flex-grow:1;align-self: center;display: flex;padding: 40px;padding-bottom:0;font-size: 1.2em">
            <div style="align-self: center;flex:1">
              <div style="margin-top:4px;color: #666">
                {{ $t('dialog.number_saved_info') }}
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div
              @click="set_phonenumber"
              v-if="mode=='pass'" style="margin-top: 10px;text-decoration: underline">
            {{ $t('dialog.manage_order_phone') }}
            <br/>
            {{ $t('dialog.order_status_sent') }}
          </div>
          <div
              @click="set_passnumber"
              v-else style="margin-top: 10px;text-decoration: underline">
            {{ $t('dialog.no_phone_manage_order') }}
            <br/>
            {{ $t('dialog.manage_order_password') }}
          </div>
        </template>

        <div v-if="!tablet_auth && !no_point && mode=='phone'"
             style="display:flex;margin-top:18px;width: 220px">
          <div style="flex-grow:1;font-size: 1.1em">
            <my-check v-model="point" :label="$t('dialog.earn_store_points')" id="point"/>
          </div>
          <div style=";align-self: center;display: flex;">
            <div style="align-self: center;flex:1" v-if="false">
              <div v-if="false" @click="point=!point">{{ $t('dialog.earn_store_points') }}</div>
            </div>
            <div style="width:37px;align-self: flex-start">
              <div class="mini_btn" @click="detail=!detail">{{ $t('dialog.more_info') }}</div>
            </div>
          </div>
        </div>
        <div style="padding:20px;padding-bottom: 0;" v-if="detail">
          {{ $t('dialog.details') }}
        </div>


        <template v-if="tablet_auth">
          <div class="d-flex" style="width: 100%;padding-top:40px;padding-right: 20px;padding-left: 20px;">
            <div
                :style="'font-size:'+(default_font_size*1.2)+'em;cursor:pointer'"
                class="btn_cancel"
                @touchend="without_point(0)"
                @click="without_point(1)"
            >
              {{ $t('dialog.dont_earn_points') }}
            </div>
            <div style="width:15px"/>
            <div
                :style="'font-size:'+(default_font_size*1.2)+'em;cursor:pointer'"
                class="btn_next" @touchend="check_num(0)"
                @click="check_num(1)"
            >
              {{ $t('dialog.continue') }}
            </div>
          </div>
        </template>
        <template v-else>
          <div class="d-flex" style="width: 100%;padding-top:40px;padding-right: 20px;padding-left: 20px;">
            <div
                :style="'font-size:'+(default_font_size*1.2)+'em;cursor:pointer'"
                class="btn_cancel"
                @touchend="cancel(0)"
                @click="cancel(1)"
            >{{ $t('dialog.cancel') }}
            </div>
            <div style="width:15px"/>
            <div
                :style="'font-size:'+(default_font_size*1.2)+'em;cursor:pointer'"
                class="btn_next" @touchend="check_num(0)"
                @click="check_num(1)"
            >{{ $t('dialog.confirm') }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MyCheck from "../form/MyCheck2.vue";

export default {
  name: "NumericKeypad",
  computed: mapGetters({
    torder_no: 'torder_no',
    prepay: 'prepay',
    tablet_auth: 'tablet_auth',
    shop_uid: 'shop_uid',
    default_font_size: 'default_font_size',
    pay_type: 'pay_type',
    cart_items: 'cart_items',
    pay_amount: 'pay_amount',
    kiosk_host: 'kiosk_host',
    touchable: 'touchable',
    point_num: 'point_num',
    receipt_num: 'receipt_num'
  }),
  data: () => ({
    order_noti:false,
    point: true,
    num_type: '',
    show: false,
    detail: false,
    message: '',
    phone_number: '010',
    phone_number_only: '',

    no_point: false,
    change_mode: false,
    smart_order: false,
    cancel_txt: '',
    json_data: {},
    mode: {
      default: 'phone',
      type: String
    }
  }),
  components: {
    MyCheck
  },
  watch: {
    phone_number: {
      handler() {
        this.phone_number = this.phoneNumber(this.phone_number)
      },
    },
  },
  beforeUnmount() {
    this.emitter.off('enter_phone_num_table_order')
    this.emitter.off('change_phone_num_table_order')
    this.emitter.off('enter_phone_num_tablet_order')
  },
  mounted() {
    this.cancel_txt = this.$t('dialog.cancel') // Use translation key

    this.emitter.on('enter_phone_num_tablet_order', this.start_tablet_order)
    this.emitter.on('enter_phone_num_table_order', this.set_phonenumber)
    this.emitter.on('change_phone_num_table_order', this.change_phonenumber)
  },
  methods: {
    start_tablet_order() {
      var url = '/torder-api/tableorder/table/tablet?table_no=' + this.torder_no
      this.$http.get(url).then(res => {
        try {
          var o = res.data.table_info
          if (o.phone_number) {
            this.emitter.emit("tablet_order_start", null)
          } else {
            this.start_tablet_order2()
          }
        } catch (e) {
          this.start_tablet_order2()
        }
      }).catch(err => {
        try {
          if (err.data.errorMessage.indexOf("ERR302") >= 0) {
            this.start_tablet_order2()
            return
          }
        } catch (e) {}
        this.emitter.emit("tablet_order_start", null)
      })
    },
    start_tablet_order2() {
      this.no_point = false
      this.cancel_txt = this.$t('dialog.cancel') // Use translation key
      this.message = `${this.$t('dialog.manage_order_phone')}<br/><span style="font-size: 1.4em;font-weight: bold;">${this.$t('dialog.enter_phone')}</span><br/><br/>${this.$t('dialog.order_status_sent')}`
      this.phone_number = '010'
      this.mode = 'phone'
      this.show = true
    },
    set_passnumber() {
      this.cancel_txt = this.$t('dialog.cancel') // Use translation key

      this.message = this.$t('enter_table_password');

      //this.message = `${this.$t('dialog.manage_order_password')}<br/><span style="font-size: 1.4em;font-weight: bold;">${this.$t('dialog.enter_password')}</span><br/><br/>${this.$t('dialog.prevent_others_access')}`

      this.mode = 'pass'
      this.phone_number = ''
      var v = this.$cookie.getCookie2('mypass_number')
      if (v) this.phone_number = v
      this.show = true
    },
    change_phonenumber() {
      this.change_mode = true
      this.cancel_txt = this.$t('dialog.cancel') // Use translation key
      this.message = `${this.$t('dialog.manage_order_phone')}<br/><span style="font-size: 1.4em;font-weight: bold;">${this.$t('dialog.enter_phone')}</span><br/><br/>${this.$t('dialog.order_status_sent')}`
      this.phone_number = '010'
      this.mode = 'phone'
      var v = this.$cookie.getCookie2('myphone_number')
      if (v) this.phone_number = v
      this.show = true
    },
    set_phonenumber(no_point) {
      this.no_point = no_point ? true : false
      this.change_mode = false
      this.cancel_txt = this.$t('dialog.cancel') // Use translation key
      this.message = `${this.$t('dialog.manage_order_phone')}<br/><span style="font-size: 1.4em;font-weight: bold;">${this.$t('dialog.enter_phone')}</span>`
      this.phone_number = '010'
      this.mode = 'phone'
      var v = this.$cookie.getCookie2('myphone_number')
      if (v) this.phone_number = v
      this.show = true
    },
    add(num, ty) {
      if (this.mode == 'pass' && this.phone_number.length >= 4) return
      if (this.shop_uid && ty == 0) {
        return
      } else if (!this.shop_uid && ty == 1) {
        return
      }
      var pn = this.phone_number.length
      if (pn >= 17) return
      this.phone_number += num
    },
    phoneNumber(value) {
      if (this.mode == 'pass') return value
      if (!value) {
        return "";
      }
      value = value.replace(/[^0-9]/g, "");
      this.phone_number_only = value;
      let result = [];
      let restNumber = "";
      if (value.startsWith("02")) {
        result.push(value.substr(0, 2));
        restNumber = value.substring(2);
      } else if (value.startsWith("1")) {
        restNumber = value;
      } else {
        result.push(value.substr(0, 3));
        restNumber = value.substring(3);
      }
      if (restNumber.length === 7) {
        result.push(restNumber.substring(0, 3));
        result.push(restNumber.substring(3));
      } else {
        result.push(restNumber.substring(0, 4));
        result.push(restNumber.substring(4));
      }
      return result.filter((val) => val).join(" - ");
    },
    backspace(ty) {
      if (this.shop_uid && ty == 0) {
        return
      } else if (!this.shop_uid && ty == 1) {
        return
      }
      this.phone_number = this.phone_number.slice(0, -1)
    },
    numValidation() {
      let number = this.phone_number_only
      var regExp = /^(?:(010\d{4})|(01[1|6|7|8|9]\d{3,4}))(\d{4})$/;
      if (!regExp.test(number)) {
        return false
      }
      return true
    },
    without_point(ty) {
      if (this.shop_uid && ty == 0) {
        return
      } else if (!this.shop_uid && ty == 1) {
        return
      }
      if (this.prepay) {
        this.emitter.emit("tablet_order_request_prepay", null)
      } else {
        this.emitter.emit("tablet_order_start", null)
      }
      this.show = false
    },
    check_num(ty) {
      if (this.shop_uid && ty == 0) {
        return
      } else if (!this.shop_uid && ty == 1) {
        return
      }
      if (this.mode != 'pass' && this.numValidation() == false) {
        this.$toast.error(this.$t('invalid_phone_number'), {
          position: "top"
        })
        return
      } else if (this.mode == 'pass' && this.phone_number.length < 4) {
        this.$toast.error(this.$t('dialog.enter_4_digits'), {
          position: "top"
        })
        return
      }
      if (this.tablet_auth) {
        if (this.prepay) {
          this.emitter.emit("tablet_order_request_prepay", this.phone_number)
        } else {
          this.emitter.emit("tablet_order_start", this.phone_number)
        }
        this.show = false
        return
      }
      if (this.change_mode) {
        this.show = false
        this.emitter.emit("change_phone_num_table_order_callback", {
          pass: this.phone_number,
          earn_point: this.point
        })
        return
      }
      if (this.mode == 'pass') {
        this.$cookie.setCookie365('mypass_number', this.phone_number)
        this.$cookie.setCookie365('mypass_type', 'pass')
        this.$store.commit('torder_pass', {
          value: this.phone_number
        })
        this.$store.commit('torder_type', {
          value: 'pass'
        })
      } else if (this.mode == 'phone') {
        this.$cookie.setCookie365('myphone_number', this.phone_number_only)
        this.$cookie.setCookie365('mypass_type', 'phone')
        this.$store.commit('torder_phone', {
          value: this.phone_number_only
        })
        this.$store.commit('torder_type', {
          value: 'phone'
        })
      }
      this.$store.commit('earn_point', {
        value: this.point
      })
      this.show = false

      this.emitter.emit("order_noti", this.order_noti)
      this.emitter.emit("torder_ok")
    },
    cancel(ty) {
      if (this.shop_uid && ty == 0) {
        return
      } else if (!this.shop_uid && ty == 1) {
        return
      }
      this.show = false
    }
  }
}
</script>

<style scoped>
.btn_number {
  background: #ffffff;
  border: 1px solid var(--qro-primary-color);
  width: 7vh;
  height: 7vh;
  text-align: center;
  border-radius: 50%;
  color: var(--qro-primary-color);
  font-size: 4vh;
  line-height: 7vh;
}

.btn_number:active {
  background: var(--qro-primary-color);
  color: #ffffff;
}

.btn_number2 {
  width: 7vh;
  height: 7vh;
  text-align: center;
  color: var(--qro-primary-color);
  font-size: 4vh;
  line-height: 7vh;
}

.dialog_content2 {
  padding: 6px;
  color: #250063;
  font-weight: 500;
  text-align: center;
}
.mini_btn {
  font-size:11px;
  /*border: 1px #666 solid;*/
  background-color: var(--qro-secondary-color);
  color:#fff;
  padding:3px;
  align-self: center;
  border-radius: 3px;
  display: inline-block;
}

</style>