<template>
  <div v-if="show" class="dialog">
    <div class="rounded_card" style="width:80%;max-width:600px">
      <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 20px;">
        <div class="dialog_content" v-html="message" :style="'font-size:' + (default_font_size * 1.2) + 'em'"></div>
        <div
            :style="'border-bottom: var(--qro-primary-color)2px solid; font-size: ' + (default_font_size * 1.3) + 'em; padding: 0.5em;width: 76%; text-align: center'">
          <span v-if="exist_number" v-text="exist_number"></span>
          <span v-else>------------</span>
        </div>
        <div style="height: 20px;"></div>
        <div class="dialog_content2" :style="'font-size:' + (default_font_size * 1.3) + 'em;display:flex;'">
          <div class="btn_number" @touchend="add('1', 0)" @click="add('1', 1)">1</div>
          <div style="width:2vh"></div>
          <div class="btn_number" @touchend="add('2', 0)" @click="add('2', 1)">2</div>
          <div style="width:2vh"></div>
          <div class="btn_number" @touchend="add('3', 0)" @click="add('3', 1)">3</div>
        </div>
        <div class="dialog_content2" :style="'font-size:' + (default_font_size * 1.3) + 'em;display:flex;'">
          <div class="btn_number" @touchend="add('4', 0)" @click="add('4', 1)">4</div>
          <div style="width:2vh"></div>
          <div class="btn_number" @touchend="add('5', 0)" @click="add('5', 1)">5</div>
          <div style="width:2vh"></div>
          <div class="btn_number" @touchend="add('6', 0)" @click="add('6', 1)">6</div>
        </div>
        <div class="dialog_content2" :style="'font-size:' + (default_font_size * 1.3) + 'em;display:flex;'">
          <div class="btn_number" @touchend="add('7', 0)" @click="add('7', 1)">7</div>
          <div style="width:2vh"></div>
          <div class="btn_number" @touchend="add('8', 0)" @click="add('8', 1)">8</div>
          <div style="width:2vh"></div>
          <div class="btn_number" @touchend="add('9', 0)" @click="add('9', 1)">9</div>
        </div>
        <div class="dialog_content2" :style="'font-size:' + (default_font_size * 1.3) + 'em;display:flex;'">
          <div class="btn_number" style="border:0"></div>
          <div style="width:2vh"></div>
          <div class="btn_number" @touchend="add('0', 0)" @click="add('0', 1)">0</div>
          <div style="width:2vh"></div>
          <div class="btn_number2" @touchend="backspace(0)" @click="backspace(1)">
            <img src="../../assets/images/backspace.svg" style="width: 90%;margin-top: 0.7vh;">
          </div>
        </div>

        <div class="d-flex" style="width: 100%;padding-top:40px;padding-right: 20px;padding-left: 20px;">
          <div
              :style="'font-size:' + (default_font_size * 1.2) + 'em;cursor:pointer'"
              class="btn_cancel"
              @touchend="cancel(0)"
              @click="cancel(1)"
          >{{ $t('cancel') }}
          </div>
          <div style="width:15px"/>
          <div
              :style="'font-size:' + (default_font_size * 1.2) + 'em;cursor:pointer'"
              class="btn_next" @touchend="check_num(0)"
              @click="check_num(1)"
          >{{ $t('confirm') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import MyCheck from "../form/MyCheck.vue";
export default {
  name: "NumericKeypad",
  computed: mapGetters({
    shop_uid: 'shop_uid',
    default_font_size: 'default_font_size',
    pay_type: 'pay_type',
    cart_items: 'cart_items',
    pay_amount: 'pay_amount',
    kiosk_host: 'kiosk_host',
    touchable: 'touchable',
    point_num: 'point_num',
    receipt_num: 'receipt_num',
    torder_no: 'torder_no',
    torder_pass: 'torder_pass',
    torder_phone: 'torder_phone',
    torder_type: 'torder_type',
  }),
  data: () => ({
    point: true,
    num_type: '',
    show: false,
    detail: false,
    message: '',
    exist_number: '',
    smart_order: false,
    cancel_txt: '취소',
    callback: null,
    json_data: {},
    mode: {
      default: 'phone',
      type: String
    }
  }),
  components: {
    MyCheck
  },
  watch: {
    phone_number: {
      handler() {
        this.phone_number = this.phoneNumber(this.phone_number)
      },
    },
  },
  beforeUnmount() {
    this.emitter.off('enter_exist_num_table_order')
    this.emitter.off('enter_exist_num_table_order_close')
  },
  mounted() {
    // 이벤트 등록
    this.emitter.on('enter_exist_num_table_order', this.set_current_number)
    this.emitter.on('enter_exist_num_table_order_close', this.set_current_number_close)
  },
  methods: {
    set_current_number_close: function () {
      this.show = false
    },
    set_current_number(callback) {
      this.callback = callback

      this.cancel_txt = this.$t('cancel')
      this.message = this.$t('enter_existing_phone_or_password')

      this.mode = 'pass'

      var seq = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
      //console.log(seq);
      this.phone_number = seq

      // 마지막 주문 번호를 기억한다
      var v = this.$cookie.getCookie2('mypass_number')
      if (v)
        this.phone_number = v

      this.show = true
    },
    set_passnumber() {
      this.cancel_txt = this.$t('cancel')
      this.message = this.$t('enter_password')

      this.mode = 'pass'

      var seq = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
      //console.log(seq);
      this.phone_number = seq

      // 마지막 주문 번호를 기억한다
      var v = this.$cookie.getCookie2('mypass_number')
      if (v)
        this.phone_number = v

      this.show = true
    },
    set_phonenumber() {
      this.cancel_txt = this.$t('cancel')
      this.message = this.$t('enter_phone_number2')
      this.phone_number = '010'

      this.mode = 'phone'

      // 마지막 주문 번호를 기억한다
      var v = this.$cookie.getCookie2('myphone_number')
      if (v)
        this.phone_number = v

      this.show = true
    },
    add(num, ty) {
      if (this.shop_uid && ty == 0) {
        return
      } else if (!this.shop_uid && ty == 1) {
        return
      }

      var pn = this.phone_number.length
      if (pn >= 17)
        return

      this.exist_number += num
    },
    backspace(ty) {
      if (this.shop_uid && ty == 0) {
        return
      } else if (!this.shop_uid && ty == 1) {
        return
      }

      this.exist_number = this.exist_number.slice(0, -1)
    },
    numValidation() {
      let number = this.phone_number_only
      var regExp = /^(?:(010\d{4})|(01[1|6|7|8|9]\d{3,4}))(\d{4})$/;
      if (!regExp.test(number)) {
        return false
      }
      return true
    },
    check_num(ty) {
      if (this.shop_uid && ty == 0) {
        return
      } else if (!this.shop_uid && ty == 1) {
        return
      }

      if (this.callback) {
        this.callback(this.exist_number)
      }

      return
    },
    cancel(ty) {
      if (this.shop_uid && ty == 0) {
        return
      } else if (!this.shop_uid && ty == 1) {
        return
      }

      this.show = false
    }
  }
}
</script>

<style scoped>
.btn_number {
  background: #ffffff;
  border: 1px solid var(--qro-primary-color);
  width: 7vh;
  height: 7vh;
  text-align: center;
  border-radius: 50%;
  color: var(--qro-primary-color);
  font-size: 4vh;
  line-height: 7vh;
}

.btn_number:active {
  background: var(--qro-primary-color);
  color: #ffffff;
}

.btn_number2 {
  width: 7vh;
  height: 7vh;
  text-align: center;
  color: var(--qro-primary-color);
  font-size: 4vh;
  line-height: 7vh;
}

.dialog_content2 {
  padding: 6px;
  color: #250063;
  font-weight: 500;
  text-align: center;
}

.mini_btn {
  font-size: 11px;
  background-color: var(--qro-secondary-color);
  color: #fff;
  padding: 3px;
  align-self: center;
  border-radius: 3px;
  display: inline-block;
}
</style>
