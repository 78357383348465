<template>
  <!--
  <ul>
    <li>
      <input id="c1" type="checkbox">
      <label for="c1">Checkbox</label>
    </li>
    <li>
      <input id="c2" type="checkbox" checked>
      <label for="c2">Checkbox</label>
    </li>
    <li>
      <input id="r1" type="radio" name="radio" value="1">
      <label for="r1">Radio</label>
    </li>
    <li>
      <input id="r2" type="radio" name="radio" value="2" checked>
      <label for="r2">Radio</label>
    </li>
    <li>
      <input id="s1" type="checkbox" class="switch">
      <label for="s1">Switch</label>
    </li>
    <li>
      <input id="s2" type="checkbox" class="switch" checked>
      <label for="s2">Switch</label>
    </li>
  </ul>

  <ul>
    <li>
      <input id="c1d" type="checkbox" disabled>
      <label for="c1d">Checkbox</label>
    </li>
    <li>
      <input id="c2d" type="checkbox" checked disabled>
      <label for="c2d">Checkbox</label>
    </li>
    <li>
      <input id="r1d" type="radio" name="radiod" value="1" disabled>
      <label for="r1d">Radio</label>
    </li>
    <li>
      <input id="r2d" type="radio" name="radiod" value="2" checked disabled>
      <label for="r2d">Radio</label>
    </li>
    <li>
      <input id="s1d" type="checkbox" class="switch" disabled>
      <label for="s1d">Switch</label>
    </li>
    <li>
      <input id="s2d" type="checkbox" class="switch" checked disabled>
      <label for="s2d">Switch</label>
    </li>
  </ul>
  //-->

  <details ref="details"
  >
    <summary
        :class="getMainStyle"
        :style="getSummaryStyle + ';font-size:'+(default_font_size*1.1)+'em;'"
        @click="clk_menu"
    >
      <div v-html="title">
      </div>

      <div
          v-if="selected_multi && selected_multi.length>0"
          style="display: flex;flex-wrap: wrap;margin:10px -10px -10px -10px">
        <div class="chip-option" v-for="option in selected_multi">
          <span v-text="option_name(option)"/>
        </div>
      </div>

    </summary>

  </details>


  <Modal
      v-model="show" max-width="500px" style="overflow: hidden;position:relative;">
    <div style="display: flex;flex-direction: column;max-height: 100%;border-radius: 20px;overflow: hidden">
      <div style="overflow-y: auto;flex:1;max-height: 50vh">
        <ul class="list" style="margin:0">
          <li
              v-if="selection_mode=='normal'"
              v-for="item in _items" style="margin:12px"
              @click="select_item(item)"
          >
            <div
                :style="'font-size:'+(default_font_size*1.1)+'em;margin-bottom:12px;display:flex'">
              <div>
                <span
                    :style="item.sold_out?'text-decoration:line-through':''"
                    v-text="item.id?item.name:title"></span>
                <span
                    v-if="item.sold_out"
                >
                  &nbsp;(품절)
                </span>
              </div>
              <div style="flex-grow: 1">
              </div>
              <div
                  v-if="item.price"
                  style="font-weight: bold;margin-left: 10px;color:yellow"
              >
                <span style="font-size: 0.8em">+</span>{{numberWithCommas(item.price)}}
              </div>
            </div>
          </li>

          <li
              v-else-if="selection_mode=='checkbox'"
              v-for="(item,idx) in _items" style="margin:12px"

              @click.stop="checked_checkbox(idx)"
          >
            <div
                :style="'font-size:'+(default_font_size*1.1)+'em;margin-bottom:12px;display:flex'">
              <div v-if="!item.id" v-text="title">
              </div>
              <div style="display: flex">
                <div style="margin-right: 10px;">
                  <input type="checkbox"
                         v-model="item.selected"
                         :value="true"
                         :disabled="item.sold_out"
                         @change="clicked_checkbox(idx)"
                  >
                </div>
                <div style="margin-right: 10px;"
                     @click="clicked_checkbox2(idx)"
                >
                  <span
                      :style="item.sold_out?'text-decoration:line-through':''"
                  >
                    {{item.name}}
                  </span>
                  <span
                      v-if="item.sold_out"
                  >
                  &nbsp;(품절)
                </span>

                </div>
              </div>
              <div style="flex-grow: 1"
                   @click="clicked_checkbox2(idx)"
              >
              </div>
              <div
                  @click="clicked_checkbox2(idx)"
                  v-if="item.price"
                  style="font-weight: bold;margin-left: 10px;color:yellow"
              >
                <span style="font-size: 0.8em">+</span>{{numberWithCommas(item.price)}}
              </div>
            </div>
          </li>
        </ul>

      </div>
      <div
          v-if="selection_mode!='normal'"
          style="background-color:var(--qro-primary-color);"
      >
        <div
            style="height:40px;color:#fff;padding:1em;display: flex;justify-content: center;border-radius:0;font-weight:bold"
        >
          <div style="align-self:center;text-align:center;padding:0 15px">
            <b :style="computed_selection">선택 : {{selected_multi.length}}</b>
          </div>
          <div style="flex-grow:1"></div>
          <div style="align-self:center;padding:0 15px">
            최소 : {{min_selection}} / 최대 : {{max_selection}}
          </div>
        </div>
        <div
            style="color:#fff;padding:0 1em 1em 1em;display: flex;justify-content: center;border-radius:0;font-weight:bold">
          <div style="align-self:center;text-align:center;padding:0px;flex:1">
            <div class="btn_cancel" @click="selected_cancel" :style="'font-size:'+(default_font_size*1.2)+'em;margin-right:1em;'">
              취소
            </div>
          </div>
          <div style="align-self:center;text-align:center;padding:0px;flex:1">
            <div class="btn_done" @click="selected_done" :style="'font-size:'+(default_font_size*1.2)+'em;'">
              완료
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>

</template>
<script>
import {mapGetters} from "vuex";
import {ref} from "vue";

import 'vue-neat-modal/dist/vue-neat-modal.css'
import { Modal } from 'vue-neat-modal'

export default {
  props: {
    warning: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '옵션'
    },
    mtype: {
      type: Number,
      default: 0
    },
    mandatory: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    max_selection: {
      type: Number,
      default: 0
    },
    min_selection: {
      type: Number,
      default: 0
    },
    modelValue: Object
  },
  components: {
    Modal
  },
  mounted() {

    this.init_items()

    this.init_selection()

    if (this.modelValue) {
      this.my_value = this.modelValue
      this.find_selected_item()
    }

    // type
    else if (!this.mandatory) {

      /*
      // 비 필수옵션 - 최상단에 항목으로 추가된 상태이다
      // -> 체크박스로 변경해서 의미 없다
      this.my_value = this
          .items[0]
          .id
      */
    }

  },
  setup() {
    const details = ref(null)
    return {details}
  },
  computed: {
    computed_selection:function() {
      if(this.min_selection <= this.selected_multi.length) {
        // ok
        return ''
      } else {
        return 'color:yellow'
      }
    },
    getMainStyle:function() {

      if(this.disabled)
        return 'mdisabled'
      else if(this.mandatory)
        return 'ani'
      return ''
    },
    getSummaryStyle: function () {
      if(this.mandatory)
        return 'background-color:var(--qro-primary-color);color:#fff;font-weight:normal'
      else if(!this.mandatory
          //&& !this.my_value
      ) {
        return 'color:#777;font-weight:normal;'
      }
      else if(this.mandatory && this.disabled) {
        return 'color:#777;font-weight:normal;border:var(--qro-primary-color) 2px solid;'
      }
      else {
        return 'background-color:var(--qro-primary-color);color:#fff;font-weight:normal'
      }

      /*
      return 'background-color:#e22b2b;color:#fff;font-weight:normal'
      return !this.mandatory&&!this.my_value?'color:#777;font-weight:normal;':'border:2px solid var(--qro-secondary-color)'

      if(this.warning) {
          return 'background-color:#e22b2b;color:#777;font-weight:normal;'
      } else
          return !this.mandatory&&!this.my_value?'color:#777;font-weight:normal;':'border:2px solid var(--qro-secondary-color)'
      */
    },
    ...mapGetters({default_font_size: 'default_font_size'
    }),
    getPlaceholderStatus: function () {
      if (this.isValid() == 0)
        return true
      else if (this.isValid() == -1) {
        if (this.my_value != undefined && this.my_value != null && this.my_value != '')
          return false

        return true
      } else
        return false
    },
    // eslint-disable-next-line vue/return-in-computed-property
    getStatus: function () {

      if (this.isValid() == -1)
        return 'status2 disabled'
      if (this.isValid() == 1)
        return 'status2 on'
      if (this.isValid() == 0)
        return 'status2'
    }
  },
  watch: {
    show:function() {
      this.init_items()
    },
    items:function() {
      this.init_items()
    },
    min_selection: function() {
      this.init_selection()
    },
    max_selection: function() {
      this.init_selection()
    },
    modelValue: function () {
      this.my_value = this.modelValue
      this.find_selected_item()
    },
    my_value: function () {
      this.$emit('update:modelValue', this.my_value)
      this.$emit('change', this.my_value)
    }
  },
  data: () => ({
    _items:[],
    selected_multi:[],
    selected_title: '', my_value: null,
    selection_mode:'normal',
    show: false
  }),
  methods: {
    init_items:function() {
      if(this.items)
        this._items=JSON.parse(JSON.stringify(this.items))

      /*
      var idx=0
      this._items.forEach(el=>{
        if(idx++%2==0) {
          el.sold_out = true
        }
      })
      */

      if(this.selected_multi) {
        this._items.forEach(el=>{
          if(this.selected_multi.includes(el.id)) {
            el.selected = true
          } else {
            el.selected = false
          }
        })
      }
    },
    option_name:function(item) {
      let name = ''
      this._items.forEach(el=>{
        if(el.id == item)
          name = el.name
      })
      return name
    },
    selected_cancel:function() {
      this.my_value = null
      this.selected_multi = []
      this.details.removeAttribute("open");
      this.show=false
    },
    selected_done:function() {
      if(this.min_selection <= this.selected_multi.length) {
        // ok
        this.details.removeAttribute("open");
        this.show=false

        this.confirmed()

      } else {

        this.$swal({
          title:'최소 '+this.min_selection+'개 이상 선택해주세요.',
          icon:'error',
          allowOutsideClick:false,
          confirmButtonText:'확인'
        });
        return;

      }
    },
    checked_checkbox:function() {
      this.check_selection()
    },
    check_selection:function() {

      var current_selection = 0
      this._items.forEach(el=>{
        if(el.selected) {
          current_selection++
          // ok
        }
      })

      // max_validation
      var len = current_selection
      if(this.max_selection < len) {

        // 이미 선택된 아이템이 아니면 disable
        this._items.forEach(el=>{
          if(el.selected) {
            if(this.selected_multi.includes(el.id)) {
              // ok
            } else {
              el.selected = false
            }
          }
        })

        return
      }

      this.selected_multi = []
      this._items.forEach(el=>{
        if(el.selected)
          this.selected_multi.push(el.id)
      })
    },
    clicked_checkbox2:function(idx) {
      //console.log(idx)
      if(this._items[idx].sold_out)
        return

      this._items[idx].selected = !this._items[idx].selected
      this.check_selection()
    },
    clicked_checkbox:function(idx) {
      this.check_selection()
    },
    init_selection:function() {
      //console.log('init_selection')
      if(this.mandatory && this.min_selection == this.max_selection && this.max_selection==1)
        this.selection_mode = 'normal'
      else
        this.selection_mode = 'checkbox'
    },
    numberWithCommas: function (x) {
      return x
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    clk_menu: function () {
      if(this.disabled)
        return
      this.show=true
    },
    find_selected_item: function () {
      // find
      if (!this.items)
        return

      this.items.forEach(el => {
        if (el.id == this.my_value) {
          this.selected_title = el.name
        }
      })
    },
    select_item: function (item) {

      if(item.sold_out)
        return

      this.selected_multi = []
      this.selected_multi.push(item.id)
      /*
      this.my_value = item.id
      this.selected_title = item.name
      */

      this.details.removeAttribute("open");
      this.show = false

      this.confirmed()
    },
    confirmed:function() {

      // value 를 만든다
      if(this.selected_multi) {
        this.my_value = this.selected_multi
      } else {
        this.my_value = null
      }

    },
    clk: function (item) {
      this.my_value = item.id
    },
    isValid: function () {
      var stat = 0
      if (this.disabled) {
        return -1
      } else if (this.my_value != undefined && this.my_value != null && this.my_value != '') {
        return 1
      }
      return stat
    }
  }
}
</script>
<style>
.swal2-container {
  z-index: 100000002;
}
.bottom_panel_alert {
  z-index: 100000000;
}
.top_panel_alert {
  position: fixed;left: 0;bottom:0;width: 100%;
  z-index: 100000000;
}
.vue-neat-modal {
  max-height: 80% !important;
}
.vue-neat-modal-backdrop {
  z-index: 99999998;
  background-color: #ffffff99 !important;
}
.vue-neat-modal-wrapper {
  z-index: 99999999;
}
.vue-neat-modal::-webkit-scrollbar {
  width: 5px;
}
.vue-neat-modal::-webkit-scrollbar-thumb {
  background-color: #00000088;
  border-radius: 100px;
}
</style>
<style scoped="scoped">
.chip-option {
  background-color: #333;
  color:white;
  border-radius: 7px;
  padding: 5px 12px;
  margin: 6px;
}
.btn_cancel {
  border-radius: 12px;
  background-color: white;
  color: #333;
  border: #333 1px solid;;
  font-weight: bold;
  padding: 10px;
}
.btn_done {
  border-radius: 12px;
  background-color: var(--qro-secondary-color);
  color: white;
  font-weight: bold;
  padding: 10px;
}
@keyframes glowing {
  0% {
    background-color: #e1005d;
    box-shadow: 0 0 5px #e1005d;
  }
  50% {
    background-color: #e1005d;
    box-shadow: 0 0 20px #e1005d;
  }
  100% {
    background-color: #e1005d;
    box-shadow: 0 0 5px #e1005d;
  }
}
.ani {
  animation: glowing 1300ms infinite;
}

.status2 {
  margin: -2px;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: rgb(252, 196, 23);
  position: absolute;
  left: 0;
  top: 0;
}

.status2.on {
  background-color: rgb(80, 164, 125);
}

.status2.disabled {
  background-color: #ddd;
}

.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.wrapper .tabs_wrap {
  border-bottom: 1px solid #ebedec;
}

.wrapper .tabs_wrap ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.wrapper .tabs_wrap ul li {
  text-align: center;
  background: #fff;
  border-right: 1px solid #c1c4c9;
  padding: 25px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.wrapper .tabs_wrap ul li:first-child {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.wrapper .tabs_wrap ul li:last-child {
  border-right: 0;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.wrapper .tabs_wrap ul li.active,
.wrapper .tabs_wrap ul li:hover {
  background: #6d4b4b;
  color: #fff;
}

.select_border {
  /*  border: 2px #eee solid;*/
  color: #020202;
  font-weight: bold;
  padding: 5px;
  border-radius: 15px;
}

.option select {
  background-color: #f0f0f0;
  color: #020202;
  padding: 5px;
  font-weight: bold;
}

.placeholder {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px), calc(100% - 15px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
}

details {
  position: relative;
  width: 100%;
}

details[open] {
  z-index: 1;
}

summary {
  padding: 1rem;
  cursor: pointer;
  border-radius: 15px;

  font-weight: bold;
  background-color: #f0f0f0;
  list-style: none;
  -webkit-tap-highlight-color: transparent; /* remove tap highlight */
}

summary::-webkit-details-marker {
  display: none;
}

details[open] summary:before {
  /*
  content: '';
  display: block;
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  */
  display: none;
}

summary:after {
  /*
  content: '';
  display: inline-block;
  float: right;
  width: 0.5rem;
  height: 0.5rem;
  border-bottom: 1px solid currentColor;
  border-left: 1px solid currentColor;
  border-bottom-left-radius: 2px;
  transform: rotate(45deg) translate(50%, 0%);
  transform-origin: center center;
  transition: transform ease-in-out 100ms;
   */
}

summary:focus {
  outline: none;
}

details[open] summary:after {
  /*
  transform: rotate(-45deg) translate(0%, 0%);
   */
}

ul {
  width: 100%;
  background: var(--qro-secondary-color);
  color: #fff;
  /*
  position: absolute;
top: calc(100% - 28px);
  */

  left: 0;
  padding: 1rem;
  box-sizing: border-box;
  max-height: 60%;
  overflow-y: auto;
  /*margin-top: 3px;*/
}

li {
  margin: 0;
  padding: 1rem 0;
  border-bottom: 1px solid #ccc;
}

li:first-child {
  padding-top: 0;
}

li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

/* FAKE SELECT */

ul.list {
  counter-reset: labels;
  border: 1px solid var(--qro-secondary-color);
}

label {
  width: 100%;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
}

label span {
  --display: none;
  display: var(--display);
  width: 1rem;
  height: 1rem;
  border: 1px solid #727272;
  border-radius: 3px;
}
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type=checkbox],
  input[type=radio] {
    --active: var(--qro-primary-color);
    --active-inner: #fff;
    --focus: 2px rgba(219, 29, 135, .3);
    --border: #BBC1E1;
    --border-hover: rgb(219, 29, 135, .8);
    --background: #fff;
    --disabled: #666666;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 23px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  }
  input[type=checkbox]:after,
  input[type=radio]:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
  }
  input[type=checkbox]:checked,
  input[type=radio]:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
  }
  input[type=checkbox]:disabled,
  input[type=radio]:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }
  input[type=checkbox]:disabled:checked,
  input[type=radio]:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }
  input[type=checkbox]:disabled + label,
  input[type=radio]:disabled + label {
    cursor: not-allowed;
  }
  input[type=checkbox]:hover:not(:checked):not(:disabled),
  input[type=radio]:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }
  input[type=checkbox]:focus,
  input[type=radio]:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  input[type=checkbox]:not(.switch),
  input[type=radio]:not(.switch) {
    width: 23px;
  }
  input[type=checkbox]:not(.switch):after,
  input[type=radio]:not(.switch):after {
    opacity: var(--o, 0);
  }
  input[type=checkbox]:not(.switch):checked,
  input[type=radio]:not(.switch):checked {
    --o: 1;
  }
  input[type=checkbox] + label,
  input[type=radio] + label {
    font-size: 14px;
    line-height: 23px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 4px;
  }

  input[type=checkbox]:not(.switch) {
    border-radius: 7px;
  }
  input[type=checkbox]:not(.switch):after {
    width: 5px;
    height: 9px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 7px;
    top: 4px;
    transform: rotate(var(--r, 20deg));
  }
  input[type=checkbox]:not(.switch):checked {
    --r: 43deg;
  }
  input[type=checkbox].switch {
    width: 38px;
    border-radius: 11px;
  }
  input[type=checkbox].switch:after {
    left: 2px;
    top: 2px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background: var(--ab, var(--border));
    transform: translateX(var(--x, 0));
  }
  input[type=checkbox].switch:checked {
    --ab: var(--active-inner);
    --x: 17px;
  }
  input[type=checkbox].switch:disabled:not(:checked):after {
    opacity: 0.6;
  }

  input[type=radio] {
    border-radius: 50%;
  }
  input[type=radio]:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, 0.7));
  }
  input[type=radio]:checked {
    --s: .5;
  }
}
</style>