<template>


  <div class="wrap">

    <div class="title">
      <p>테이블오더 포인트결제</p>
      <div class="messageBox">
        <img class="messageImg" src="/images/important.png"/>
        <p class="messageTitle"><b>포인트 사용을 먼저 해주세요</b></p>
      </div>
      <!-- <p class="messageInfo">직원에게 별도 요청 없이 체크아웃 어플에서 내가 쌓은 포인트로 결재금액을 차감 하실 수 있습니다.</p> -->
      <p class="messageInfo">포인트는 착석한 테이블에서 사용할 수 있습니다.<br/>포인트 사용 후 나머지 금액 결제는 카운터에서 도와드립니다.</p>
    </div>

    <div style="margin-bottom:50px;">
      <div class="tGroup">
        <div class="num">1.</div>
        <p>주문하신 테이블에 부착된 QR코드를 체크아웃 앱에서 스캔해주세요.</p>
      </div>
      <img class="guideImg1" src="/images/1_1.png"/>
      <img class="guideImg1" src="/images/1_2.png"/>
      <img class="guideImg1" src="/images/1_3.png"/>
    </div>
    <div style="margin-bottom:50px;">
      <div class="tGroup">
        <div class="num">2.</div>
        <p>결제에 사용할 포인트를 입력하시고 계속하기 버튼을 눌러주시면, 보유 포인트가 차감됩니다.</p>
      </div>
      <img class="guideImg2" src="/images/2_1.png"/>
      <img class="guideImg2" src="/images/2_2.png"/>
    </div>
    <div style="margin-bottom:30px;">
      <div class="tGroup">
        <div class="num">3.</div>
        <p>사용하신 포인트는 결제 금액에서 차감되며, 나머지 금액을 계산해주세요.</p>
      </div>
    </div>
  </div>

</template>
<script>
export default {
	mounted() {
  }
}
</script>
<style scoped>

.wrap{
  width:100%;
  box-sizing: border-box;
  padding:30px 30px;
  background-size: 70px;
  background-color: #ffffff99;
}
.title{
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  color:#fff;
  padding:20px 20px;
  background-color: var(--qro-primary-color);
  border-radius: 20px;
  margin:0 auto;
  margin-bottom:50px;
  max-width: 400px;
}
.titleImg{
  width:100%;
  max-width: 300px;
  margin:0 auto;
  display: block;
  margin-bottom:50px;
}
.num{
  color:var(--qro-primary-color);
  font-size: 30px;
  font-weight: bold;
  line-height: 2.2;

}

.tGroup{
  display: flex;
  max-width: 400px;
  margin: 0 auto;
}
p{
  font-size: 20px;
  padding-left:10px;
  word-break: keep-all;
}
.guideImg1{
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
  max-width: 400px;
  display: block;
  margin:0 auto;
  margin-bottom: 30px;
  box-shadow: 0px 0px 20px rgba(0,0,0,0.08);
}
.guideImg2{
  width: 100%;
  max-width: 400px;
  display: block;
  margin:0 auto;
}
.footer{
  color:#ffffff;
  text-align: center;

  padding-top:30px;
  padding-bottom:30px;
  background-color:var(--qro-primary-color);
  word-break: keep-all;
}
.f1{
  padding:0px 30px;
  font-size: 20px;
}
.f2{
  margin-top:20px;
  font-size: 12px;
}
.messageBox{
  border-radius: 20px;
  width:100%;
  padding:10px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  margin-top:30px;
}
.messageTitle{
  color:var(--qro-primary-color);
  font-size: 20px;
  letter-spacing: -2px;

}
.messageImg{
  width:50%;
  display: block;
  margin:0 auto;
  padding:20px;
  padding-bottom: 0px;
}
.messageInfo{
  color:#fff;
  font-size: 18px;
  padding:10px;
  padding-bottom:0px;
  text-align: left;
  word-break: keep-all;

}
</style>