<template>
<div :style="$route.path.indexOf('/review')==0?'height: 100%;overflow-y: auto':''">
  <div class="wrap" v-if="review_detail">

    <!-- title -->
    <div class="title">
      <p class="ps">리뷰 등록하면</p>
      <template v-if="review_detail.benefit_type=='discount'">
        <p class="pl">{{review_detail.discount_amount.toString().price2()}}원</p>
        <p class="ps">즉시 할인!</p>
      </template>
      <template v-else>
        <p class="pl" style="font-size: 2.1em;padding: 10px 0">{{review_detail.benefit_detail.toString()}}</p>
        <p class="ps">서비스 제공!</p>
      </template>
      <div style="position: relative">
        <p class="arrow2"></p>
      </div>
    </div>

    <!--

        "naver_receipt": 0,
        "instagram": 0,
        "google_map": 0,
    //-->
    <div class="reviewInfo">
      <div style="display: flex;justify-content: center;width: 100%;">
        <div style="flex: 1;text-align: center" v-if="review_detail.instagram"><img src="/images/insta.png" style="max-width: 100%;max-height: 300px"></div>
        <div style="flex: 1;text-align: center" v-if="review_detail.naver_receipt"><img src="/images/naver.png" style="max-width: 100%;max-height: 300px"></div>
        <div style="flex: 1;text-align: center" v-if="review_detail.google_map"><img src="/images/google.png" style="max-width: 100%;max-height: 300px"></div>
      </div>

      <div class="info" style="margin-top: -100px">
        <p v-if="tablet_auth">리뷰를 등록하고, 등록된 리뷰 화면을 캡쳐, 아래 <b>QR 코드를</b> 촬영해서 업로드 해주시면 즉시 할인 또는 서비스를 제공해드립니다.</p>
        <p v-else>리뷰를 등록하고, 등록된 리뷰 화면을 캡쳐, 아래 <b>[제출하기]</b> 버튼을 클릭해서 업로드 해주시면 즉시 할인 또는 서비스를 제공해드립니다.</p>
        <div style="padding: 5px 0">
          * 별도 직원 호출없이 비대면으로 참여가능합니다.
        </div>

        <div class="receiptButton2" @click="download_img()" v-if="review_detail.naver_receipt">
          <p>1. 리뷰 등록 영수증 받기</p>
        </div>
        <div style="margin-top: 15px" @click="write_review" class="receiptButton2" v-if="review_detail.naver_receipt">
          <p>2. 네이버리뷰 작성하기</p>
        </div>

        <div v-if="!tablet_auth" @click="submit_review" class="receiptButton">
          <p style="font-size: 2em">{{$t('submit')}}</p>
        </div>

        <div class="tabletQR" v-else>

          <div style="display: flex;justify-content: center">
            <qr-code :size="170" :text="getReviewUrl()" style="justify-content: center;margin-top: 15px"/>
          </div>

          <p class="tabletInfo">QR 코드를 휴대폰으로 촬영해주세요</p>
        </div>

      </div>
    </div>
  </div>
</div>

<!--
  <div v-if="showDialog" class="modal-overlay">
    <div class="modal-content">
      <p>이미지를 길게 눌러서 갤러리로 저장해주세요.</p>
      <img :src="imgurl" alt="Image to Save" style="width: 100%; max-width: 300px;" />
      <button @click="showDialog=false">닫기</button>
    </div>
  </div>
//-->
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";
import VueQRCodeComponent from 'vue-qrcode-component'

export default {
  components: {
    'qr-code': VueQRCodeComponent,
  },
  computed:{
    ...mapGetters({
      tablet_auth:'tablet_auth',
      shop_info:'shop_info',
      torder_no:'torder_no',
      review_detail: 'review_detail',
    })
  },
  watch:{
    shop_info:function () {
      this.load()
    }
  },
  methods:{
    saveImageToGallery:async function(imageUrl) {
      try {
        let that = this
        // Fetch the image as a Blob
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const file = new File([blob], "receipt.png", { type: "image/png" });

        // Check if Web Share API is supported
        if (navigator.canShare && navigator.canShare({ files: [file] })) {
          await navigator.share({
            files: [file],
            title: "영수증 저장",
            text: "영수증을 갤러리에 저장합니다.",
          });
          alert("저장이 완료되었습니다!");
        } else {
          //alert("저장에 실패하였습니다.");
          that.download_img(true)
        }
      } catch (error) {
        console.error("Error sharing image:", error);
      }
    },
    download_img:function (force) {

      if(!this.imgurl) {
        //this.$toast.error('영수증을 만들고 있습니다. 잠시후 다시 시도해주세요.', { position: "top" });
        // 직접 조회 후 이동
        let that = this
        this.get_receipt(function() {
          that.$router.push({path:'review_receipt',query:{img:that.imgurl}})
        })
        return
      }


      const imageUrl = this.imgurl

      const isIPhone = /iPhone|iPad|iPod/i.test(navigator.userAgent);
      if (force || !isIPhone) {

        // 현재 창(팝업) 닫기
        this.emitter.emit("confirm_popup_close", {
        });


        // ok
        this.$router.push({path:'review_receipt',query:{img:imageUrl}})
        return

        // 아이폰이 아닌 경우만

      } else {
        this.saveImageToGallery(imageUrl)
        return;
      }


      try {

        let that = this

        const xhr = new XMLHttpRequest();
        xhr.open("GET", imageUrl, true);
        xhr.responseType = "blob";

// When the request completes, process the Blob response
        xhr.onload = function () {
          if (xhr.status === 200) {
            // Create a Blob from the response
            const blob = xhr.response;
            const newBlob = new Blob([blob], { type: "image/png" });


            // Create a URL for the Blob and set it as the href for a download link
            const link = document.createElement("a");
            const url = URL.createObjectURL(newBlob);
            link.href = url;
            link.download = "receipt.png"; // Specify the file name

            // Trigger the download
            link.click();

            // Clean up the Blob URL after download
            URL.revokeObjectURL(url);

            that.$toast.info('다운로드가 완료되었습니다.', { position: "top" });
          }
        };


        // Send the request
        xhr.send();

      } catch (error) {
        console.log(error)
        console.error("Error downloading the image:", error);
      }
    },
    get_receipt(callback) {

      // Image URL
      let tno = 1
      if(this.$route.query.tno)
        tno = this.$route.query.tno
      else
        tno = this.torder_no

      if(!tno)
        tno = 1

      let url = "/core-api/main/get/tmp_receipt?uid=" + this.shop_info.uid + "&table_no="+tno
      axios.get(url).then(res => {
        this.imgurl = res.data

        if(callback) {
          callback()
        }
        //this.$store.commit('review_detail', { value: res.data.review_info });
      }).catch(error => {
        console.log(error)
      });
    },
    write_review() {
      window.open("https://m.place.naver.com/my/checkin")
    },
    getReviewUrl() {
      let url = 'https://f.myshop.do/t/2P5AUxsUCGus?shopid='+this.shop_info.uid
      if(this.$route.query.tno)
        url+='&table_no='+this.$route.query.tno
      else
        url+='&table_no='+this.torder_no

      return url
    },
    submit_review() {
      let url = this.getReviewUrl()
      window.open(url)
    },
    data: () => ({
      //showDialog:true,
      imgurl:null
    }),
    load() {
      if(!this.shop_info)
        return

      let url = "/gw-api/main/get_review_detail?shopid=" + this.shop_info.uid+'&tno='+this.torder_no;
      axios.get(url).then(res => {
        this.$store.commit('review_detail', { value: res.data.review_info });
      }).catch(error => {
        console.log(error)
        //this.$toast.error( this.$t('shop_not_ready'), { position: "top" });
      });
    }
  },
	mounted() {
    //console.log(this.tablet_auth)

    this.get_receipt()

    if(this.shop_info)
      this.load()
  }
}
</script>
<style scoped>

.wrap{
  width:100%;
  box-sizing: border-box;
  padding:30px 30px;
  background-size: 70px;
  background-color: #ffffff99;
}

.title{
  color:#fff;
  font-weight: bold;
  background-color:var(--qro-primary-color);
  border-radius: 20px;
  padding: 30px 20px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  word-break: keep-all;
  -webkit-filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.3));
}

.ps{
  font-size: 30px;
  margin-block-start: 0;
  margin-block-end: 0;
  -webkit-transform: skew(0deg, -5deg);
  -webkit-filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.3));
}

.pl{
  font-size: 60px;
  margin-block-start: 5px;
  margin-block-end: 5px;
  -webkit-filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.3));
}

.arrow2{
  width: 40px;
  height: 40px;
  background-color: var(--qro-primary-color);
  transform: rotate(45deg);
  border-radius: 5px;
  position: absolute;
  right: 10px;
  margin-top: 5px;
}

.reviewInfo{
  position:relative;
  max-width: 440px;
  margin:0 auto;
  margin-top:50px;
}

.reviewImg{
  position:relative;
  width:90%;
  margin:0 auto;
  display: block;
  -webkit-filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.3));
  z-index: 2;
}

.info{
  font-size: 16px;
  border-radius: 20px;
  background-color: var(--qro-primary-color);
  color:#fff;
  padding:100px 20px 20px 20px;
  word-break: keep-all;
  text-align: center;
  margin-bottom: -50px;
}

.receiptButton{
  display: flex;
  right: 20px;
  padding: 10px;
  margin-top: 30px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  -webkit-filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.3));
}

.receiptButton2{
  display: flex;
  border: white 1px solid;
  right: 20px;
  padding: 10px;
  margin-top: 30px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}
.receiptButton2 p{
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  padding-left:5px;
  color:white;
}

.receiptButton p{
  font-size: 14px;
  font-weight: bold;
  margin-block-start: 5px;
  margin-block-end: 5px;
  text-align: center;
  padding-left:5px;
  color:var(--qro-primary-color);
}

.receiptIcon{
  width:30px;
  height:30px;
}

.tabletInfo{
  font-size: 12px;
  color:#fff;
  margin-block-start: 15px;
  margin-block-end: 5px;
}

.qrImage{
  margin-top:20px;
  width:100%;
}

.upload{
  max-width: 400px;
  padding:20px;
  border-radius: 20px;
  border:2px solid var(--qro-primary-color);
  background-color: rgba(255,255,255,1);
  -webkit-filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.3));
  word-break: keep-all;
  padding-top:0px;
  margin:0 auto;
}

.uploadTitleWrap{
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadTitle{
  font-size:30px;
  font-weight: bold;
  color:var(--qro-primary-color);
  padding-left: 10px;
  padding-bottom:5px;
}

.cameraIcon{
  height:25px;
}

.uploadIcon{
  width:50px;
  display: block;
  margin:0 auto;
  padding-bottom:20px;
  opacity:0.3;
}

.uploadInfo{
  font-weight: bold;
  font-size: 14px;
  color:var(--qro-primary-color);
  text-align: center;
}

.completeIcon{
  padding-top:30px;
  display: block;
  width:120px;
  margin:0 auto;
}

.completeInfo{
  font-size:20px;
  color:var(--qro-primary-color);
  text-align: center;
  font-weight: bold;
  word-break: keep-all;
}

.popup{
  z-index: 2;
  position:fixed;
  width:100%;
  height:100%;
  background-color:rgba(0,0,0,0.5);
  top:0;
  left:0;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.popupWrap{
  transform: translateY(-50%);
  top:50%;
  position:relative;
}

.receiptImage{
  display: block;
  height:250px;
  margin:0 auto;
  margin-bottom:20px;
  -webkit-filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5));
}

.downloadButton{
  margin-top: 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width:70%;
  margin:0 auto;
  border-radius: 10px;
  max-width: 250px;
  padding:0px 20px;
}

.downloadIcon{
  width:30px;
}

.downloadInfo{
  font-size:25px;
  color:var(--qro-primary-color);
  font-weight: bold;
  margin-left: 5px;
  margin-block-start: 12px;
  margin-block-end: 12px;
}

.closeIcon{
  position:absolute;
  width:35px;
  height:35px;
  right:15px;
  top:15px;
}

/* Basic modal styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.modal-content img {
  margin-top: 10px;
  border-radius: 4px;
}

.modal-content button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-content button:hover {
  background-color: #0056b3;
}
</style>