<template>
  <VueSidePanel
      v-model="show"
      rerender
      hide-close-btn
      side="bottom"
      :z-index="2"
      :panel-duration="200"
      :overlay-duration="0"
  >
    <template #default>

      <div style="height: 100%; display: flex; flex-direction: column; overflow-y: auto">

        <div style="padding: 5px 20px; overflow-y: auto">
          <div class="paybt"
               v-for="item in lang_codes"
               :key="item.code"
               @click="sel_lang(item)"
               :style="'font-size:'+(default_font_size*1.1)+'em; margin:15px 0;cursor:pointer'"
          >
            <div class="pay">
              {{ item.name }}
            </div>
          </div>

        </div>

      </div>

    </template>

    <template #footer>

      <div :style="window_vertical ? 'display:flex; padding: 1em 1em 1em 1em;' : 'display:flex; padding: 1em 1em 1em 1em;'">

        <div class="cancelbt" @click.stop="close()"
             :style="'font-size:'+(default_font_size*1.1)+'em; margin-right: 10px'"
        >
          <div style="align-self: center">
            {{ $t('close') }}
          </div>
        </div>
      </div>

    </template>
  </VueSidePanel>
</template>

<script>
import MyNumber from "@/components/form/MyNumber";
import { directive } from 'v-aspect-ratio'
import myshopDetailView from "@/mixin/DetailViewMixin";
import { mapGetters } from "vuex";
import OptionLayer from "@/components/common/OptionLayer";
import VueQRCodeComponent from 'vue-qrcode-component'

export default {
  name: "AlarmPopup",
  directives: {
    'aspect-ratio': directive,
  },
  created() {
    var w = window.innerWidth
    if (w > 500) {
      this.window_vertical = false
    } else {
      this.window_vertical = true
    }
  },
  computed: mapGetters({
    admin_pos: 'admin_pos',
    vertical: 'vertical',
    torder_no: 'torder_no',
    prod_restriction: 'prod_restriction',
    default_font_size: 'default_font_size',
    products: 'products',
    cart_items: 'cart_items',
    selected_product: 'selected_product',
    myshop_host: 'myshop_host',
    shop_uid: 'shop_uid',
    prod_options: 'prod_options',
    option_mappings: 'option_mappings',
    lang_codes:'lang_codes'
  }),
  mixins: [myshopDetailView],
  components: {
    OptionLayer,
    MyNumber
  },
  mounted() {
  },
  watch: {
    selected_product: {
      deep: true,
      handler() {
      }
    },
    show: function () {
      if (!this.show)
        this.close()
    }
  },
  data: () => ({
    items: null,
    window_vertical: false,
    show: false,
    cart_popup_products: [],
    qty: 1
  }),
  methods: {
    sel_lang: function (item) {

      this.$i18n.locale = item.code;

      //lang_default_code
      this.$cookie.setCookie365('lang_current_code', item.code);

      //console.log(item)
      //sel_lang
      this.$store.commit('lang_current_code',{value:item.code})
      this.close()

      if(!this.shop_uid) {
        localStorage.setItem('lang_current_code', item.code)
        this.$app_event("change_lang_code", item.code);
      }

      document.location.reload()
    },
    open() {
      //this.load()
      this.open2()
    },
    open2() {
      this.show = true
      //console.log(this.default_option_list)

      this.$nextTick(() => {
        setTimeout(function () {

          try {
            var a = document.getElementsByClassName('vsp__body')
            var h = a[0].style.height
            //console.log(h)

            var a = document.getElementsByClassName('detailWrap')
            a[0].style.height = h
            a[0].style.overflowY = 'auto'
          } catch (e) { }
        }, 1000)
      })
    },
    close() {
      this.show = false
    },
  }
}
</script>

<style scoped>
.detailWrap_inner {
  /*
  transform: translate3d(0,0,0);
  -webkit-overflow-scrolling:touch;
   */
  /*height:calc(100% + 1px);*/
}
.detailWrap {
  /*
  overflow:scroll;
  transform: translate3d(0,0,0);
  -webkit-overflow-scrolling:touch;
    height: 100%;
  overflow:auto;
   */
}

/*
.detailWrap {
  transform: translate3d(0,0,0);
  overflow:scroll;
  -webkit-overflow-scrolling:touch;
}
.option_layer {
  height:calc(100% + 1px)
}
*/
.prod_img {
  width: 100%;
  border-radius: 15px;
  background-position: center;
  background-size: cover;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #2f354299;
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  background-color: #e1e1e199;
  border-radius: 5px;
}
.title_pos {
  margin: 0;
}
.xgrid_org_price {
  color: #ddd;
  text-align: right;
  justify-content: right;
}
.xgrid_org_price2 {
  color: var(--qro-primary-color);
  font-weight: bold;
  text-align: right;
  justify-content: right;
}
.xgrid_org_price3 {
  font-weight: bold;
  text-align: right;
  justify-content: right;
}
.badge2 {
  background-color: red;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 5px;
  height: 20px;
  font-size: 16px;
}
</style>
<style>
.vsp__body {
  overflow: hidden !important;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;;
  /*
   */
}
.vsp-wrapper .vsp {
}
.vsp {
  overflow: hidden !important;
  border-top-left-radius: 20px !important;; border-top-right-radius: 20px !important;;
}

</style>
